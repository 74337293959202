import { FC, ReactNode } from "react";
import { Dialog } from "@material-ui/core";

interface ICustomModal {
  children: ReactNode;
  showModal: boolean;
  handleClose: () => void;
}

const CustomModal: FC<ICustomModal> = ({ children, showModal, handleClose }) => {
  return (
    <div>
      <Dialog onClose={handleClose} open={showModal}>
        <div>{children}</div>
      </Dialog>
    </div>
  );
};

export default CustomModal;
