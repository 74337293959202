// import { storeData } from '../utils/Storage';
import Actions from "./Actions";
import {
  IActions,
  IAppState,
  IState,
} from "./interfaces";

export const AppReducer = (state: IAppState, action: IActions): IAppState => {
  switch (action.type) {
    case Actions.SET_APP_STATE: {
      return { ...state, foreground: action.payload };
    }
    case Actions.SET_CONNECTIVITY_CHANGE:
      return { ...state, hasConnection: action.payload };
    case Actions.UPDATE_IDENTIFIER: {
      return {
        ...state,
        tools: {
          ...state.tools,
          isIdentifier: action.payload,
        },
      };
    }
    case Actions.UPDATE_LOCATION: {
      return {
        ...state,
        tools: {
          ...state.tools,
          isLocation: action.payload,
        },
      };
    }
    case Actions.UPDATE_UTM_CONVERT: {
      return {
        ...state,
        tools: {
          ...state.tools,
          isUtmConvert: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

const reducerMap: any = {
  AppReducer: "appState",
  AuthenticationReducer: "authenticationState",
};

export const combinedReducers =
  (slices: any) =>
  (state: IState, action: IActions): IState =>
    Object.keys(slices).reduce(
      (acc: any, val: any) => ({
        ...acc,
        [reducerMap[val]]: slices[val](acc[reducerMap[val]], action),
      }),
      state
    );

export const rootReducer = combinedReducers({
  AppReducer,
});
