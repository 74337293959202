import { FC } from "react";
import styled from "styled-components";
import { colors, fonts } from "../../../theme/base";
import labels from "constants/labels.json";
import FailIcon from "components/common/assets/FailIcon";
import { Layout, P } from "./styled";

export interface IPanelPasswordVerfication {
  capsLetterFlag?: boolean;
  numberFlag?: boolean;
  pwdLengthFlag?: boolean;
  specialCharFlag?: boolean;
  matchPasswordFlag?: boolean;
}

const { UpdatePasswordPage: { validations: ComponentLabels} } = labels

const FlagText = ({ text, flag }: { text: string; flag: boolean }) => {
  return (
    <Layout>
      <FailIcon color={flag ? colors.secondary : colors.fail} />
      <P>{text}</P>
    </Layout>
  );
};

const PanelPasswwordValidation: FC<IPanelPasswordVerfication> = ({
  capsLetterFlag,
  numberFlag,
  pwdLengthFlag,
  specialCharFlag,
  matchPasswordFlag,
}) => {
  return (
    <div>
      {capsLetterFlag !== undefined && (
        <FlagText text={ComponentLabels.capitalLetter} flag={capsLetterFlag} />
      )}

      {numberFlag !== undefined && (
        <FlagText text={ComponentLabels.numberAtLeastOne} flag={numberFlag} />
      )}

      {pwdLengthFlag !== undefined && (
        <FlagText text={ComponentLabels.lengthAtLeastEight} flag={pwdLengthFlag} />
      )}

      {specialCharFlag !== undefined && (
        <FlagText text={ComponentLabels.specialChar} flag={specialCharFlag} />
      )}

      {matchPasswordFlag !== undefined && (
        <FlagText text={ComponentLabels.matchNewPassword} flag={matchPasswordFlag} />
      )}
    </div>
  );
};

export default PanelPasswwordValidation;
