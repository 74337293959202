import { FC, ReactElement, useCallback, useState } from "react";
import { Form, Title, WrapperBottom } from "./styled";
import { colors } from "theme/base";
import { Button } from "components/common/Button";
import PasswordPanelVerification, {
  IPanelPasswordVerfication,
} from "./components/PasswordPanelVerification";
import labels from "constants/labels.json";
import { InputField } from "components/common/InputField";
import KeyPasswordIcon from "components/common/assets/KeyPasswordIcon";
import SaveIcon from "components/common/assets/SaveIcon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import CustomModal from "components/CustomModal/CustomModal";
import Link from "@material-ui/core/Link";
import styled from "styled-components";
import { Box } from "@mui/material";
import { useChangePassword } from "./hooks/useChangePassword";
import { useAuthentication } from "context/AuthenticationContext";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const { UpdatePasswordPage: PageLabels } = labels;

const verifyAllChecks = (
  checks: IPanelPasswordVerfication | TConfirmChecks
) => {
  return Object.values(checks).some((elem) => elem === false);
};

type TConfirmChecks = Pick<IPanelPasswordVerfication, "matchPasswordFlag">;

const UpdatePassword: FC = (): ReactElement => {

  // let navigate = useNavigate();
  // const dispatch = useDispatch();
  // const { loggedUser } = useCurrentToken();
  // const { loading } = useSelector(
  //   (state: RootState) => state.authentication
  // );
  // const [messageToast, setMessageToast] = useState<IMessageToast>(errorObject);
  
  const [visible, setVisible] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checksNew, setChecksNew] = useState<IPanelPasswordVerfication>({
    capsLetterFlag: false,
    numberFlag: false,
    pwdLengthFlag: false,
    specialCharFlag: false,
  });
  const [checksConfirm, setChecksConfirm] = useState<TConfirmChecks>({
    matchPasswordFlag: false,
  });
  const { token } = useAuthentication();
  const { isLoading: isUpdatePasswordLoading, updatePassword } = useChangePassword({
    onSuccess: () => {
      console.log('EXITO!!!');
    },

    onError: () => {
      console.log('error')
    },
  });

  // NEW PASSWORD
  const handleOnChangeNew = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };

  const handleOnFocusNew = () => {
    setChecksNewPassword(newPassword);
    //setShowPanelVerificationNew(true);
  };

  const handleOnBlurNew = () => {
    //setShowPanelVerificationNew(false);
  };

  const setChecksNewPassword = useCallback((value: string) => {
    const capsLetterCheck = /[A-Z]/.test(value);
    const numberCheck = /[0-9]/.test(value);
    const pwdLengthCheck = value.length >= 8;
    const specialCharCheck = /[!@#$%^&*]/.test(value);
    setChecksNew({
      capsLetterFlag: capsLetterCheck,
      numberFlag: numberCheck,
      pwdLengthFlag: pwdLengthCheck,
      specialCharFlag: specialCharCheck,
    });
  }, []);

  const handleOnKeyUpNew = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setChecksNewPassword(value);
  };
  // CONFIRM PASSWORD
  const handleOnChangeConfirm = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
  };

  const handleOnFocusConfirm = () => {
    setChecksConfirmPassword(confirmPassword);
    //setShowPanelVerificationConfirm(true);
  };

  const handleOnBlurConfirm = () => {
    //setShowPanelVerificationConfirm(false);
  };

  const setChecksConfirmPassword = useCallback(
    (value: string) => {
      const matchPasswordCheck = newPassword === confirmPassword;
      setChecksConfirm({
        matchPasswordFlag: matchPasswordCheck,
      });
    },
    [confirmPassword, newPassword]
  );

  const handleOnKeyUpConfirm = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const { value } = event.target as HTMLInputElement;
    setChecksConfirmPassword(value);
  };

  const isFormValid = () =>
    !currentPassword || !newPassword || !confirmPassword;
  const isChecksValid = () =>
    verifyAllChecks(checksNew) || verifyAllChecks(checksConfirm);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('DATA: token: ', { token }, ' - new: ', { newPassword }, ' - renew', { newPassword }, ' - current: ', { currentPassword })
    updatePassword({ token, newPassword, reNewPassword: newPassword, currentPassword })
  };

  return (
    <>
      <Link component="button" variant="body2" onClick={() => setVisible(true)}>
        <AccountCircleIcon style={{color:'white'}} />
      </Link>
      <CustomModal showModal={visible} handleClose={() => setVisible(false)}>
        <Container>
          <div>
            <Box display={"flex"}>
              <IconButton onClick={() => setVisible(false)} >
                <ArrowBackIcon fontSize="large"/>
              </IconButton>
              <Title>{PageLabels.title}</Title>
            </Box>
            <p>{PageLabels.description}</p>
            <Form onSubmit={handleSubmit}>
              <InputField
                label={PageLabels.form.currentPassword}
                type="password"
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder=""
                iconStart={<KeyPasswordIcon color={colors.grayLight} />}
                value={currentPassword}
              />
              <InputField
                label={PageLabels.form.newPassword}
                type="password"
                onChange={handleOnChangeNew}
                placeholder=""
                iconStart={<KeyPasswordIcon color={colors.grayLight} />}
                onFocus={handleOnFocusNew}
                onBlur={handleOnBlurNew}
                onKeyUp={handleOnKeyUpNew}
                value={newPassword}
              />

              <PasswordPanelVerification
                capsLetterFlag={checksNew.capsLetterFlag}
                numberFlag={checksNew.numberFlag}
                pwdLengthFlag={checksNew.pwdLengthFlag}
                specialCharFlag={checksNew.specialCharFlag}
              />

              <InputField
                label={PageLabels.form.confirmPasswod}
                type="password"
                onChange={handleOnChangeConfirm}
                placeholder=""
                iconStart={<KeyPasswordIcon color={colors.grayLight} />}
                onFocus={handleOnFocusConfirm}
                onBlur={handleOnBlurConfirm}
                onKeyUp={handleOnKeyUpConfirm}
                value={confirmPassword}
              />
              <PasswordPanelVerification
                matchPasswordFlag={checksConfirm.matchPasswordFlag}
              />
              <WrapperBottom justifyContent="flex-end">
                <Button
                  width="12rem"
                  type="submit"
                  text={PageLabels.buttonSuccess}
                  icon={<SaveIcon color={colors.white} />}
                  // loading={loading}
                  // disabled={loading}
                />
              </WrapperBottom>
            </Form>
          </div>
        </Container>
      </CustomModal>
    </>
  );
};

export default UpdatePassword;
