import L from "leaflet";
import { LatLng } from "leaflet";
import { useEffect, useState } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import { useGlobalContext } from "state/Context";

let DefaultIcon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});

L.Marker.prototype.options.icon = DefaultIcon;

const LocationMarker = () => {
  const { dispatch } = useGlobalContext();
  const [position, setPosition] = useState<LatLng | null>(null);
  const [bbox, setBbox] = useState<string []>([]);

  const map = useMap();

  useEffect(() => {
    map.locate().on("locationfound", function (event) {
      // const { lat, lng } = event.latlng;
      setPosition(event.latlng);
      map.flyTo(event.latlng, map.getZoom());
      const radius = event.accuracy;
      const circle = L.circle(event.latlng, radius);
      circle.addTo(map);
      // const a = event.bounds.toBBoxString().split(",");
      setBbox(event.bounds.toBBoxString().split(","));
      // dispatch({ type: Actions.UPDATE_LOCATION, payload: false });
    });
  }, [dispatch, map]);

  return position === null ? null : (
    <Marker position={position}>
      <Popup>
        <b>Southwest lng</b>: {bbox[0]} <br />
        <b>Southwest lat</b>: {bbox[1]} <br />
        <b>Northeast lng</b>: {bbox[2]} <br />
        <b>Northeast lat</b>: {bbox[3]}
      </Popup>
    </Marker>
  );
}

export default LocationMarker;
