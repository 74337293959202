export const colors = {
  primary: '#2196f3',
  secondary: '#4FA8F0',
  white: '#FFFFFF',
  paleGrey: '#F2F4FA',
  lightGrey: '#E1E4ED',
  midGrey: '#C1C4CF',
  darkGrey: '#707587',
  black: '#141415',
  failLight: '#FFF6F8',
  fail: '#E20029',
  warn: '#E99A00',
  success: '#6CC000',
  grayLight: '#868E96',
  grayVeryLight: '#CED4DA',
  greenVeryLight: '#CEFCE5',
  link: '#0000EE',
  blue: '#77A6ED',
  skyblue1: '#2196f3',
  skyblue2: '#6eb8f5',
  skyblue3: '#9acef8',
  skyblue4: '#c3c3fd',
  skyblue5: '#e8f5ff',
  selectedFeature: '#00FFFF',
};

export const fonts = {
  bold: 'MontserratBold',
  medium: 'MontserratMedium',
  semiBold: 'MontserratSemiBold',
  normal: 'Montserrat',
};
