import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Fragment, useState } from "react";
import { InputField } from "components/common/InputField";
import { Form, WrapperBottom } from "pages/login/styled";
import { colors } from "theme/base";
import labels from "constants/labels.json";
import MailIcon from "components/common/assets/MailIcon";
import { useFeature } from "hooks/useFeature";
import { IManzanoFeature, IPredioFeature } from "util/interfaces";
import { Button as BButton } from "components/common/Button";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import { useCurrentState, useCurrentStateM } from "context/StateContext";
import { mutationManzano, mutationPredio } from "util/Array";
import Control from "react-leaflet-custom-control";
import { Search as SearchIcon } from "@mui/icons-material";
import { useMap } from "react-leaflet";

type Anchor = "top" | "left" | "bottom" | "right";

const { SignInPage: PageLabels } = labels;

export const CustomDrawer = () => {
  const { currentPredio, setCurrentPredio } = useCurrentState();
  const { setCurrentManzano } = useCurrentStateM();
  const map = useMap()
  const { data } = useFeature();
  const [value, setValue] = useState("");
  const currentAnchor: Anchor = "bottom";
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if(value.length>0){
      console.log('es NaN?: ',isNaN(+value));
      if(isNaN(+value)){
        const via = async (cod: string) => {
          const res = await fetch("http://www.geoportal.colcapirhua.gob.bo:8000/Backend/vias_gral/busqueda/"+cod);
          return res.json();
        };
        via(value).then((res)=>{
          const datos = JSON.parse(res['Vias encontradas']);
          console.log('respuesta vias: ',datos.features[0]);
        //   const found = `{
        //     "type": "Feature",
        //     "geometry": {
        //         "type": "Polygon",
        //         "coordinates": [
        //             [
        //                 [
        //                     [
        //                         -66.25165199521629,
        //                         -17.40361868479629
        //                     ],
        //                     [
        //                         -66.24982994055252,
        //                         -17.403895831691024
        //                     ],
        //                     [
        //                         -66.2498285351608,
        //                         -17.403896028135232
        //                     ],
        //                     [
        //                         -66.24953376509212,
        //                         -17.403933881658777
        //                     ],
        //                     [
        //                         -66.24952614185214,
        //                         -17.403935487783123
        //                     ],
        //                     [
        //                         -66.24951890152703,
        //                         -17.403938282195874
        //                     ],
        //                     [
        //                         -66.24951224092617,
        //                         -17.403942187240094
        //                     ],
        //                     [
        //                         -66.2495063459929,
        //                         -17.40394709711419
        //                     ],
        //                     [
        //                         -66.2495013767984,
        //                         -17.403952874955664
        //                     ],
        //                     [
        //                         -66.24949747210867,
        //                         -17.403959361934703
        //                     ],
        //                     [
        //                         -66.24949473805889,
        //                         -17.403966379807116
        //                     ],
        //                     [
        //                         -66.2494932509207,
        //                         -17.403973734564712
        //                     ],
        //                     [
        //                         -66.2494930514167,
        //                         -17.403981093540946
        //                     ],
        //                     [
        //                         -66.24949305133802,
        //                         -17.403981224483264
        //                     ],
        //                     [
        //                         -66.24958251340844,
        //                         -17.405473771366808
        //                     ],
        //                     [
        //                         -66.2495923040817,
        //                         -17.40567449818962
        //                     ],
        //                     [
        //                         -66.24959330268604,
        //                         -17.405681872792435
        //                     ],
        //                     [
        //                         -66.24959554770213,
        //                         -17.4056889900623
        //                     ],
        //                     [
        //                         -66.24959897994057,
        //                         -17.405695656831217
        //                     ],
        //                     [
        //                         -66.24960350616745,
        //                         -17.405701692104273
        //                     ],
        //                     [
        //                         -66.24960900184436,
        //                         -17.405706932515816
        //                     ],
        //                     [
        //                         -66.2496153195383,
        //                         -17.40571123605763
        //                     ],
        //                     [
        //                         -66.24962228794064,
        //                         -17.40571448477475
        //                     ],
        //                     [
        //                         -66.24962971647466,
        //                         -17.40571659115029
        //                     ],
        //                     [
        //                         -66.24963740468631,
        //                         -17.405717499138145
        //                     ],
        //                     [
        //                         -66.25178526885581,
        //                         -17.405799745067043
        //                     ],
        //                     [
        //                         -66.2517927953652,
        //                         -17.405799454190486
        //                     ],
        //                     [
        //                         -66.25180017729791,
        //                         -17.405798012629084
        //                     ],
        //                     [
        //                         -66.25180722503346,
        //                         -17.40579545750339
        //                     ],
        //                     [
        //                         -66.25181375698313,
        //                         -17.40579185494254
        //                     ],
        //                     [
        //                         -66.25181960527304,
        //                         -17.40578729745353
        //                     ],
        //                     [
        //                         -66.25182462141377,
        //                         -17.40578190219321
        //                     ],
        //                     [
        //                         -66.25182867635435,
        //                         -17.405775807356765
        //                     ],
        //                     [
        //                         -66.25183166523844,
        //                         -17.405769168631053
        //                     ],
        //                     [
        //                         -66.25183351120656,
        //                         -17.405762156537826
        //                     ],
        //                     [
        //                         -66.2518341673444,
        //                         -17.40575495194533
        //                     ],
        //                     [
        //                         -66.25184751383813,
        //                         -17.403942965754084
        //                     ],
        //                     [
        //                         -66.25184701399711,
        //                         -17.403936072132677
        //                     ],
        //                     [
        //                         -66.25184542356624,
        //                         -17.403929332350256
        //                     ],
        //                     [
        //                         -66.25184278063425,
        //                         -17.403922904064185
        //                     ],
        //                     [
        //                         -66.2517049399016,
        //                         -17.40365072822479
        //                     ],
        //                     [
        //                         -66.25170326073606,
        //                         -17.40364709193702
        //                     ],
        //                     [
        //                         -66.2517029891921,
        //                         -17.403646443409407
        //                     ],
        //                     [
        //                         -66.25169943720506,
        //                         -17.40363966665309
        //                     ],
        //                     [
        //                         -66.25169475404606,
        //                         -17.403633556113412
        //                     ],
        //                     [
        //                         -66.2516890706889,
        //                         -17.403628285177046
        //                     ],
        //                     [
        //                         -66.25168254857887,
        //                         -17.403624002365067
        //                     ],
        //                     [
        //                         -66.25167537120926,
        //                         -17.40362082850763
        //                     ],
        //                     [
        //                         -66.25166774229476,
        //                         -17.403618853106565
        //                     ],
        //                     [
        //                         -66.25165987640767,
        //                         -17.403618131497158
        //                     ],
        //                     [
        //                         -66.25165199521629,
        //                         -17.40361868479629
        //                     ]
        //                 ]
        //             ]
        //         ]
        //     },
        //     "properties": {
        //         "OBJECTID": 219,
        //         "Shape_Leng": 910.906068229,
        //         "Shape_Area": 53610.8237842,
        //         "CODIFICACI": "014",
        //         "pk": "211"
        //     }
        // }`;
        //   const datex = JSON.parse(found);
        //   console.log('datex: ',datex);
          // datex.geometry.type = 'MultiPolygon';
          let coordenadas = [];
          coordenadas.push(datos.features[0].geometry.coordinates);
          let datosModif = datos.features[0];
          datosModif.geometry.coordinates = coordenadas;
          console.log('datosModif: ',datosModif);
          if(res){
            setCurrentPredio(mutationPredio(datosModif));
          }
        }).catch((err)=>{
          console.log('el error: ',err);
        });
      } else
      if (value.length === 9) {
        // const found = data.features.find(
        //   (element: IPredioFeature) => element.properties.codsist === value
        // );
        const predio = async (cod: string) => {
          const res = await fetch("http://www.geoportal.colcapirhua.gob.bo:8000/Backend/predios/busqueda/"+cod);
          return res.json();
        };
        // if (found) {
        //   setCurrentPredio(mutationPredio(found));
        // }
        
        // const datex = JSON.parse(found);
        // console.log("RESULT DE LA BUSQUEDAX ", { found });
        console.log('value: ',value);
        predio(value).then((res)=>{
          const datos = JSON.parse(res['Predios Encontrados']);
          console.log('respuesta: ',datos.features[0]);
          if(res){
            setCurrentPredio(mutationPredio(datos.features[0]));
          }
        }).catch((err)=>{
          console.log('el error: ',err);
        });
      }else if(value.length === 3){
        const manzano = async (cod: string) => {
          const res = await fetch("http://www.geoportal.colcapirhua.gob.bo:8000/Backend/manzanos/busqueda/"+cod);
          return res.json();
        };
        manzano(value).then((res)=>{
          const datos = JSON.parse(res['Manzanos encontrados']);
          console.log('respuesta manzano: ',datos.features[0]);
          let coordenadas = [];
          coordenadas.push(datos.features[0].geometry.coordinates);
          let datosModif = datos.features[0];
          datosModif.geometry.coordinates = coordenadas;
          console.log('datosModif: ',datosModif);
          if(res){
            setCurrentPredio(mutationPredio(datosModif));
            
            // setTimeout(() => {
            //   setCurrentPredio(mutationPredio(datosModif));
            //   if (map && currentPredio) {
            //     const { positions } = currentPredio;
            //     map.flyToBounds(positions!);
            //   }else{
            //     console.log("ups: ",map," y predio ",currentPredio);
            //   }
            // }, 1000);
            // setCurrentManzano(mutationManzano(datosModif));
          }
        }).catch((err)=>{
          console.log('el error: ',err);
        });
      }
    };
    // event.target.reset();
    // const { target } = event
    // if (target) target.reset()
    // (event.target as HTMLInputElement).value = '';
    // document.getElementById('formSearch').reset()
    setValue('');
  }

  return (
    <div>
      <Fragment key={currentAnchor}>
        <Control prepend position="topleft">
          <ButtonGroup orientation="vertical" variant="contained">
            <Tooltip placement="left" title="Buscar manzanos, predios o vías">
              <Button
                variant="contained"
                color="info"
                onClick={toggleDrawer(currentAnchor, true)}
              >
                <SearchIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Control>
        <Drawer
          anchor={currentAnchor}
          open={state[currentAnchor]}
          onClose={toggleDrawer(currentAnchor, false)}
        >
          <div style={{ height: "70px" }}>

            <Form onSubmit={handleSubmit} id="formSearch">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <div style={{ marginBottom: 5 }}>
                  <InputField
                    //label={"Texto"}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder=""
                    // iconStart={<KeyIcon color={colors.grayLight} />}
                    value={value}
                  />
                </div>

                <div style={{ marginLeft: 10 }}>
                  <BButton text={"Buscar"} width="6rem" />
                </div>
              </div>
            </Form>
          </div>
        </Drawer>
      </Fragment>
    </div>
  );
};

