import { FC, useCallback, useEffect, useState } from "react";
import {
  Content,
  Divider,
  Form,
  ImageView,
  Layout,
  StyledLink,
  StyledParagraph,
  Title,
  Wrapper,
  WrapperBottom,
} from "./styled";
import Escudo from "assets/escudoHorizontal.png";
import Logo from "assets/logo.png";
import labels from "constants/labels.json";
import { colors } from "theme/base";
import { InputField } from "components/common/InputField";
import { Button } from "components/common/Button";
import MailIcon from "components/common/assets/MailIcon";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useGlobalContext } from "state/Context";
import KeyIcon from "components/common/assets/keyIcon";
import { useAuthentication } from "context/AuthenticationContext";

const { SignInPage: PageLabels } = labels;

const SignIn: FC = () => {
  const { dispatch } = useGlobalContext();

  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { loading, signed, token, callSignIn, callUserInfo } = useAuthentication();

  // const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // dispatch({ type: Actions.LOGIN_SUCCESS });
    // console.log("IN HANDLE SUBMIT", isFormValid());
    const isFormValid = () => !username || !password;


    if (isFormValid()) {
      toast.error("Debe llenar los campos!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
      });
      return;
    }
    // console.log("RESULT", { signed });

    if (!await callSignIn(username, password)) {
      // console.log('TOKEN: ', { token });
      toast.error("Usuario o Contrasena incorrectos", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
      });
      return;
    }
  }, [callSignIn, password, username]);

  const getUserInfo = useCallback(async () => {
    if (await callUserInfo) {
      navigate('/planificacion', { replace: false });
    }
  }, [callUserInfo, navigate])

  useEffect(() => {
    if (signed) {
      // console.log('TOKEN:', token);
      getUserInfo();
    }
  }, [getUserInfo, signed]);

  return (
    <Layout>
      <ToastContainer autoClose={1500} />
      <Content>
        <ImageView>
          <img src={Logo} alt="" width="500" />
          {/* <img src={Escudo} alt="" width="500" /> */}
        </ImageView>
        
        <Wrapper>
          <Title>{PageLabels.title}</Title>
          <Form onSubmit={handleSubmit}>
            <InputField
              label={PageLabels.user}
              onChange={(e) => setUsername(e.target.value)}
              placeholder=""
              iconStart={<MailIcon color={colors.grayLight} />}
              value={username}
            />
            <InputField
              label={PageLabels.password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder=""
              iconStart={<KeyIcon color={colors.grayLight} />}
              value={password}
            />
            <WrapperBottom justifyContent="flex-end">
              <Button
                text={PageLabels.submit}
                // icon={<KeyIcon color={colors.white} />}
                loading={loading}
                disabled={loading}
                width="6rem"
              />
            </WrapperBottom>
          </Form>
          <Divider />
          <WrapperBottom justifyContent="space-between">
            <div>
              <StyledLink to={"/forgotPassword"}>
                {PageLabels.forgotPasswordMessage}
              </StyledLink>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "right",
              }}
            >
              <StyledParagraph>{PageLabels.visitMessage}</StyledParagraph>
              <StyledLink to={PageLabels.visitLink}>
                {PageLabels.visitLinkMessage}
              </StyledLink>
            </div>
          </WrapperBottom>
        </Wrapper>
      </Content>
    </Layout>
  );
};

export default SignIn;
