import callApi from "./callApi";
import { BASE_URL_AUTH, ENDPOINTS } from "./endpoints";

interface IAuthenticationProps {
  username: string;
  password: string;
}

export const postSignIn = async ({
  username,
  password,
}: IAuthenticationProps) => {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  const url = ENDPOINTS.AUTH;

  const response = await callApi({
    url,
    method: "post",
    data: formData,
  }, BASE_URL_AUTH);
  console.log('url:', { url }, 'body: ', { formData }, 'Response:', { response })
  return response;
};

export const getUserInfo = async (token: string | null) => {
  const response = await callApi({
    headers: { Authorization: `Token ${token}`},
    url: ENDPOINTS.USER_INFO,
    method: "get",
  }, BASE_URL_AUTH)

  return response.data;
}

export interface IChangePasswordVariables {
  token: string | null;
  newPassword: string;
  reNewPassword: string;
  currentPassword: string;
}

export const postChangePassword = async ({token, newPassword, reNewPassword, currentPassword}: IChangePasswordVariables) => {

  const formData = new FormData();
  formData.append("new_password", newPassword);
  formData.append("re_new_password", reNewPassword);
  formData.append("current_password", currentPassword);

  const response = await callApi({ headers: { Authorization: `Token ${token}` }, url: ENDPOINTS.SET_PASSWORD, method: "post", data: formData }, BASE_URL_AUTH);

  return response.data;

};
