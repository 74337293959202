import { ENDPOINTS, getEndpointWithBaseUrl } from "api/endpoints";
import { LatLngLiteral } from "leaflet";
import { IWmsLayer } from "util/interfaces";

export const CENTER_POINT: LatLngLiteral = { lat: -17.39095, lng:-66.24550};

export type TMap = 'planificacion' | 'urbanismo';

export const paramsLayer: L.WMSParams = {
  //version: '1.3.0',
  format: 'image/png',
  transparent: true,
  layers: "",
}

export const OPEN_STREET_LAYER: IWmsLayer = {
  base: '',
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
};

export const ESRI_WORLD_IMAGERY_LAYER: IWmsLayer = {
  base: '',
  url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
};

export const IMAGEN_2022: IWmsLayer = {
  base: ENDPOINTS.IMAGEN_2022,
  url: getEndpointWithBaseUrl(ENDPOINTS.IMAGEN_2022, 'wms'),
  layers: 'imagen2022'
}

export const PREDIOS_WMSLAYER: IWmsLayer = {
  base: ENDPOINTS.PREDIOS,
  url: getEndpointWithBaseUrl(ENDPOINTS.PREDIOS, 'wms'),
  layers: 'predios'
}

export const MANZANAS_WMSLAYER: IWmsLayer = {
  base: ENDPOINTS.MANZANAS,
  url: getEndpointWithBaseUrl(ENDPOINTS.MANZANAS, 'wms'),
  layers: 'manzanos'
}

/*export const PLANIFICACION_WMSLAYER: ILayer = {
  url: getEndpointWithBaseUrl(ENDPOINTS.PLANIFICACION, 'wms'),
  layers: 'areaExtensiva,areaHomologadaUrbana,limiteMunicipal,limitesDistritales'
}*/
