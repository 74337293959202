import React, { FC, useCallback, useEffect, useState } from "react";
import {
  LayersControl,
  MapContainer,
  TileLayer,
  ScaleControl,
  WMSTileLayer,
  ZoomControl,
} from "react-leaflet";
// import * as L from 'leaflet.control.opacity';
import 'leaflet.control.opacity';
import { LatLngLiteral, Map, tileLayer } from "leaflet";
import {
  CENTER_POINT,
  ESRI_WORLD_IMAGERY_LAYER,
  IMAGEN_2022,
  OPEN_STREET_LAYER,
} from "pages/constants";
import labels from "constants/labels.json";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DisplayPosition from "components/control/DiplayPosition";
import CustomMarker from "components/control/CustomMarker";
import Control from "react-leaflet-custom-control";
import { useGlobalContext } from "state/Context";
import LocationMarker from "components/control/LocationMarker";
import "leaflet/dist/leaflet.css";
import Toolbar from "components/Toolbar";
import PlanificacionLayer from "./PlanificacionLayer";
import { getLegendLayer } from "api/features";
import Legend from "components/Legend";
import { Modal } from "components/Modal/Modal";
import { CustomDrawer } from "components/Drawer/CustomDrawer";

interface IState {
  currentLocation: LatLngLiteral;
  zoom: number;
}

const { MapViewPage: PageLabels } = labels;
const { BaseLayer } = LayersControl;

const Planificacion: FC = () => {
  const {
    state: {
      appState: {
        mapPlanificacion,
        tools: { isLocation },
      },
    },
  } = useGlobalContext();

  const [map, setMap] = useState<Map | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [state, setState] = useState<IState>({
    currentLocation: CENTER_POINT,
    zoom: 13.5,
  });
  const [dataLegend, setDataLegend] = useState<Blob[]>([]);

  const getAllRequestsBlob = useCallback(async () => {
    const requests = mapPlanificacion.layers.map((element) => {
      return getLegendLayer(element.base, element.layer);
    });
    await Promise.all(requests).then((result) => {
      console.log("RESULT PROMISE ALL:", { result });
      setDataLegend(result);
    });
  }, [mapPlanificacion.layers]);

  useEffect(() => {
    getAllRequestsBlob();
    toast.info("Bienvenid@", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "colored",
    });
  }, [getAllRequestsBlob]);
  // console.log("MAP PLANIFICACION: ", { map });
  return (
    <div>
      <ToastContainer autoClose={1500} />
      <MapContainer
        zoomControl={false}
        attributionControl={true}
        center={state.currentLocation}
        // minZoom={0}
        // maxZoom={20}
        zoom={state.zoom}
        boundsOptions={{ animate: false }}
        whenReady={() => {
          // console.log("******************READY*****************");
          setIsReady(true);
        }}
        ref={(e) => setMap(e)}
        layers={[tileLayer(OPEN_STREET_LAYER.url),tileLayer.wms(IMAGEN_2022.url,{transparent:true, format:'image/png'})]}
      >
        {isLocation && <LocationMarker />}
        {isReady && (
          <Control prepend position="bottomright">
            <DisplayPosition />
          </Control>
        )}

        <Toolbar />

        <ZoomControl position="bottomleft" zoomInTitle="Aumentar" zoomOutTitle="Reducir" />

        <CustomMarker />
        <LayersControl collapsed={false} position="topright">
          <BaseLayer checked name={"Imagen 2022"}>
            <WMSTileLayer
              // maxNativeZoom={21}
              maxZoom={25} // puede mas pero se recomienda 23
              url={IMAGEN_2022.url}
              transparent={true}
              params={{ layers: "imagen2022", format: "image/png" }}
              eventHandlers={{
                unload: (event) => {
                  console.log("UN LOAD ", event);
                },
                load: (event) => {
                  console.log("LOAD ", event);
                },
              }}
            />
          </BaseLayer>
          <BaseLayer name={PageLabels.toc.baseLayers.esriWorldImagery}>
            <TileLayer
              url={ESRI_WORLD_IMAGERY_LAYER.url}
              attribution={ESRI_WORLD_IMAGERY_LAYER.attribution}
              //maxNativeZoom={29}
              maxZoom={19} //confirmado
            />
          </BaseLayer>
          <Control position="topleft">
            </Control>
          <PlanificacionLayer />
        </LayersControl>
        <ScaleControl position="bottomright" />
        {/* <Modal /> */}
        <CustomDrawer />
        <Legend data={dataLegend} />
      </MapContainer>
    </div>
  );
};

export default Planificacion;
