import { colors } from "../../../theme/base";
import { SVGRProps } from "../interfaces";

const VisibleOnIcon = ({ color = colors.black }: SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={12} fill="none">
    <path
      d="M14.523 5.256C13.153 2.133 10.41 0 7.313 0 4.188 0 1.447 2.133.075 5.256A1.29 1.29 0 0 0 0 5.687c0 .127.025.33.076.458 1.371 3.123 4.113 5.23 7.236 5.23 3.098 0 5.84-2.107 7.211-5.23.051-.127.102-.33.102-.457s-.05-.305-.102-.432Zm-3.554.431a3.657 3.657 0 0 1-3.656 3.657 3.641 3.641 0 0 1-3.657-3.656A3.657 3.657 0 0 1 7.312 2.03a3.674 3.674 0 0 1 3.657 3.656ZM7.313 3.25c-.077 0-.153.025-.229.025.127.229.229.508.229.788a1.62 1.62 0 0 1-1.625 1.625c-.305 0-.584-.077-.813-.204v.204a2.438 2.438 0 0 0 2.438 2.437A2.417 2.417 0 0 0 9.75 5.713c0-1.346-1.117-2.463-2.438-2.463Z"
      fill={color}
    />
  </svg>
)

export default VisibleOnIcon;



