import { colors } from "theme/base";
import { SVGRProps } from "../interfaces";


const FailIcon = ({ color = colors.black }: SVGRProps) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5.875c-4.16 0-7.5 3.37-7.5 7.5 0 4.16 3.34 7.5 7.5 7.5 4.13 0 7.5-3.34 7.5-7.5 0-4.13-3.37-7.5-7.5-7.5Zm-.703 4.453c0-.38.293-.703.703-.703.38 0 .703.322.703.703v3.75c0 .41-.322.703-.703.703a.677.677 0 0 1-.703-.703v-3.75Zm.703 7.266a.92.92 0 0 1-.938-.909.92.92 0 0 1 .938-.908c.498 0 .908.41.908.909 0 .498-.41.908-.908.908Z"
      fill={color}
    />
  </svg>
);

export default FailIcon;
