import { ENDPOINTS } from "api/endpoints";
import { TMap } from "pages/constants";
import { IState } from "./interfaces";

export const ADMINISTRATOR = "administrador";

export const GENERATOR = "generador";

export const appState = {
  foreground: "active",
  hasConnection: false,
  tools: {
    isIdentifier: false,
    isLocation: false,
    isUtmConvert: false,
  },
  isFetching: false,
  mapPlanificacion: {
    name: "planificacion" as TMap,
    layers: [
      {
        id: 1,
        name: "Area extensiva",
        layer: "areaExtensiva",
        base: ENDPOINTS.PLANIFICACION,
      },
      {
        id: 2,
        name: "Area homologada urbana",
        layer: "areaHomologadaUrbana",
        base: ENDPOINTS.PLANIFICACION,
      },
      {
        id: 3,
        name: "Area productiva",
        layer: "areaProductiva",
        base: ENDPOINTS.PLANIFICACION,
      },
      {
        id: 4,
        name: "Equipamientos urbanos",
        layer: "equipamientosUrbanos",
        base: ENDPOINTS.PLANIFICACION,
      },
      {
        id: 5,
        name: "Limite municipal",
        layer: "limiteMunicipal",
        base: ENDPOINTS.PLANIFICACION,
      },
      {
        id: 6,
        name: "Limites distritales",
        layer: "limitesDistritales",
        base: ENDPOINTS.PLANIFICACION,
      },
      {
        id: 7,
        name: "Rios",
        layer: "rios",
        base: ENDPOINTS.PLANIFICACION,
      },
      {
        id: 8,
        name: "Serrania Kenamari",
        layer: "serraniaKenamari",
        base: ENDPOINTS.PLANIFICACION,
      },
      {
        id: 9,
        name: "Vias",
        layer: "vias",
        base: ENDPOINTS.PLANIFICACION,
      },
      {
        id: 10,
        name: "Vias general",
        layer: "viasGeneral",
        base: ENDPOINTS.PLANIFICACION,
      },
      {
        id: 11,
        name: "Manzanos",
        layer: "manzanos",
        base: ENDPOINTS.MANZANAS,
      },
      {
        id: 12,
        name: "Predios",
        layer: "predios",
        base: ENDPOINTS.PREDIOS,
      },
    ],
  },
  mapUrbanismo: {
    name: "urbanismo" as TMap,
    layers: [
      {
        id: 1,
        name: "Areas verdes",
        layer: "areasVerdes",
        base: ENDPOINTS.URBANISMO,
      },
      {
        id: 2,
        name: "Curvas de nivel",
        layer: "curvasDeNivel",
        base: ENDPOINTS.URBANISMO,
      },
      {
        id: 3,
        name: "Lotes aprobados",
        layer: "lotesAprobados",
        base: ENDPOINTS.URBANISMO,
      },
      {
        id: 4,
        name: "Lotes PH",
        layer: "lotesPh",
        base: ENDPOINTS.URBANISMO,
      },
      {
        id: 5,
        name: "Manzanos",
        layer: "manzanos",
        base: ENDPOINTS.URBANISMO,
      },
      {
        id: 6,
        name: "Predios",
        layer: "predios",
        base: ENDPOINTS.URBANISMO,
      },
      {
        id: 7,
        name: "Zonas homogéneas",
        layer: "zonasHomogeneas",
        base: ENDPOINTS.URBANISMO,
      },
      {
        id: 8,
        name: "Zonas urbanas",
        layer: "zonasUrbanas",
        base: ENDPOINTS.URBANISMO,
      },
      {
        id: 9,
        name: "Limite municipal",
        layer: "limiteMunicipal",
        base: ENDPOINTS.PLANIFICACION,
      },
    ],
  },
};

export const authenticationState = {
  isFetching: false,
  error: false,
  signedIn: false,
  userDetails: {
    email: "",
    id: null,
    token: null,
    username: "",
  },
};

const state: IState = {
  appState,
};

export default state;
