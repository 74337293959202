import { LatLngExpression } from "leaflet";
import { head } from "lodash";
import { PREDIOS_WMSLAYER, TMap } from "pages/constants";
import { IMap } from "state/interfaces";
import { IManzanoFeature, IPredioFeature } from "./interfaces";

export const mutationPredio = (feature: IPredioFeature): IPredioFeature => {
  const { coordinates } = feature.geometry;
  const newCoords: [number, number][] | undefined = head(head(coordinates));
  var positions: [number, number][] | undefined = newCoords?.map((v: [number, number]) => [v[1], v[0]]);

  return { ...feature, positions }
};

export const mutationManzano = (feature: IManzanoFeature): IManzanoFeature => {
  const { coordinates } = feature.geometry;
  const newCoords: [number, number][] | undefined = head(head(coordinates));
  var positions: [number, number][] | undefined = newCoords?.map((v: [number, number]) => [v[1], v[0]]);

  return { ...feature, positions }
};

export const findMap = (array: IMap[], element: TMap): IMap | null => {
  const index = array.findIndex(x => x.name === element)
  if (index === -1) {
    return null;
  }

  return array[index];
}
