import Navbar from "navigation/navbar/Navbar";
import RoutePath from "navigation/RoutePath";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Catalog from "./catalog/Catalog";
import Home from "./home/Home";
import SignIn from "./login/SignIn";
import Planificacion from "./planificacion";
import Urbanismo from "./urbanismo";
import UpdatePassword from "./login/UpdatePassword";

const NavigationBar = () => {
  const location = useLocation();
  
  if ([RoutePath.SignIn, RoutePath.Root].includes(location.pathname as RoutePath)) return <></>;

  return (
    <Navbar />
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <NavigationBar />
      <Routes>
        <Route path={RoutePath.Home} element={<Home />} />
        <Route path={RoutePath.Planificacion} element={<Planificacion />} />
        <Route path={RoutePath.Urbanismo} element={<Urbanismo />} />
        <Route path={RoutePath.Catalog} element={<Catalog />} />
        <Route path={RoutePath.Root} element={<SignIn />} />
        <Route path={RoutePath.All} element={<SignIn />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
