import React, { FC, useCallback, useEffect, useState } from "react";
import {
  LayersControl,
  MapContainer,
  TileLayer,
  ScaleControl,
  WMSTileLayer,
  ZoomControl,
} from "react-leaflet";
import { LatLngLiteral, Map } from "leaflet";
import {
  CENTER_POINT,
  ESRI_WORLD_IMAGERY_LAYER,
  IMAGEN_2022,
  OPEN_STREET_LAYER,
} from "pages/constants";
import labels from "constants/labels.json";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DisplayPosition from "components/control/DiplayPosition";
import CustomMarker from "components/control/CustomMarker";
import { CustomDrawer } from "components/Drawer/CustomDrawer";
import Control from "react-leaflet-custom-control";
import { useCurrentState } from "context/StateContext";
import { useGlobalContext } from "state/Context";
import LocationMarker from "components/control/LocationMarker";
import { IPredioFeature } from "util/interfaces";
import { mutationPredio } from "util/Array";
import { useFeature } from "hooks/useFeature";
import "leaflet/dist/leaflet.css";
import Toolbar from "components/Toolbar";
import UrbanismoLayer from "./UrbanismoLayer";
import Legend from "components/Legend";
import { getLegendLayer } from "api/features";

interface IState {
  currentLocation: LatLngLiteral;
  zoom: number;
}

const { MapViewPage: PageLabels } = labels;
const { BaseLayer } = LayersControl;

const Urbanismo: FC = () => {
  const {
    state: {
      appState: {
        mapUrbanismo,
        tools: { isIdentifier, isLocation },
      },
    },
    dispatch,
  } = useGlobalContext();
  const { currentPredio, setCurrentPredio } = useCurrentState();
  const [map, setMap] = useState<Map | null>(null);
  const { data } = useFeature();
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const [value, setValue] = useState("");
  const [state, setState] = useState<IState>({
    currentLocation: CENTER_POINT,
    zoom: 13.5,
  });
  const [dataLegend, setDataLegend] = useState<Blob[]>([]);

  const getAllRequestsBlob = useCallback(async () => {
    const requests = mapUrbanismo.layers.map((element) => {
      return getLegendLayer(element.base, element.layer);
    });
    await Promise.all(requests).then((result) => {
      console.log("RESULT PROMISE ALL:", { result });
      setDataLegend(result);
    });
  }, [mapUrbanismo.layers]);

  useEffect(() => {
    getAllRequestsBlob();
    toast.info("Bienvenid@", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "colored",
    });
  }, [getAllRequestsBlob]);

  // const handleLogout = () => {
  //   navigate("/", { replace: false });
  // };

  // const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();

  //   if (value.length === 9) {
  //     const found = data.features.find(
  //       (element: IPredioFeature) => element.properties.codsist === value
  //     );
  //     if (found) {
  //       setCurrentPredio(mutationPredio(found));
  //     }
  //   }
  // };

  return (
    <div>
      <ToastContainer autoClose={1500} />
      <MapContainer
        zoomControl={false}
        attributionControl={true}
        center={state.currentLocation}
        // minZoom={0}
        // maxZoom={20}
        zoom={state.zoom}
        boundsOptions={{ animate: false }}
        whenReady={() => {
          setIsReady(true);
          console.log("READY");
        }}
        ref={(e) => setMap(e)}
      >
        {isLocation && <LocationMarker />}
        {isReady && (
          <Control prepend position="bottomright">
            <DisplayPosition />
          </Control>
        )}

        <Toolbar />

        <ZoomControl position="bottomleft" zoomInTitle="Aumentar" zoomOutTitle="Reducir" />

        <CustomMarker />
        <LayersControl collapsed={false} position="topright">
          <BaseLayer checked name={"Imagen 2022"}>
            <WMSTileLayer
              // maxNativeZoom={21}
              maxZoom={25} // puede mas pero se recomienda 23
              url={IMAGEN_2022.url}
              params={{ layers: "imagen2022", format: "image/png" }}
              // opacity={0.1}
              eventHandlers={{
                unload: (event) => {
                  console.log("UN LOAD ", event);
                },
                load: (event) => {
                  console.log("LOAD ", event);
                },
              }}
            />
          </BaseLayer>
          <BaseLayer name={PageLabels.toc.baseLayers.openStreetMap}>
            <TileLayer
              url={OPEN_STREET_LAYER.url}
              attribution={OPEN_STREET_LAYER.attribution}
              // opacity={0.1}
              // maxNativeZoom={20}
              maxZoom={20} // 20 confirmado
            />
          </BaseLayer>
          <BaseLayer name={PageLabels.toc.baseLayers.esriWorldImagery}>
            <TileLayer
              url={ESRI_WORLD_IMAGERY_LAYER.url}
              attribution={ESRI_WORLD_IMAGERY_LAYER.attribution}
              //maxNativeZoom={29}
              maxZoom={19} //confirmado
            />
          </BaseLayer>
          <UrbanismoLayer />
        </LayersControl>
        <ScaleControl position="bottomright" />
        <CustomDrawer />
        <Legend data={dataLegend} />
      </MapContainer>
    </div>
  );
};

export default Urbanismo;
