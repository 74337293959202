import { ChangeEventHandler, FC, KeyboardEventHandler, ReactElement, useState } from "react";
import styled from "styled-components";
import { colors, fonts } from "theme/base";
import VisibleOffIcon from "components/common/assets/VisibleOffIcon";
import VisibleOnIcon from "components/common/assets/VisibleOnIcon";

const Wrapper = styled.div`
  position: relative;
`;

const WrapperIconStart = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  background-color: transparent;
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
`;

const WrapperIconEnd = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  background-color: transparent;
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
`;

const Input = styled.input`
  color: ${colors.grayLight};
  //font-family: ${fonts.medium};
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 2rem;
  padding-right: 2rem;
  border: solid ${colors.grayVeryLight};
  border-width: 2px;
  border-radius: 6px;
  &:focus {
    outline: none;
    border-color: ${colors.primary};
    border-width: 2px;
  }
`;

const Label = styled.div`
  color: ${colors.grayLight};
  //font-family: ${fonts.medium};
  font-size: 1rem;
  text-align: left;
  display: block;
  align-items: flex-start;
  margin: 0 0 0.5rem 0;
`;

const FieldWrapper = styled.div`
  display: block;
  align-items: flex-start;
  padding: 10px 0;
`;

export type TTypeInput = 'password' | 'text';

export interface IInputFieldProps {
  type?: TTypeInput;
  label?: string;
  placeholder?: string
  iconStart?: any;
  iconEnd?: any;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  value: string;
  maxLength?: number;
}

export const InputField: FC<IInputFieldProps> = ({ type = 'text', label, placeholder, iconStart, iconEnd, onChange, onFocus, onBlur, onKeyUp, value, maxLength }): ReactElement => {

  const [visible, setVisible] = useState(false);
  const [typeInput, setTypeInput] = useState<TTypeInput>('password');

  const handleClickVisible = async () => {

    if (visible) {
      await setTypeInput('password');
      setVisible(false);
    } else {
      await setTypeInput('text');
      setVisible(true);
    }
  }

  return (
    <FieldWrapper>
      {label && <Label>{label}</Label>}
      <Wrapper>
        {iconStart && (
          <WrapperIconStart>
            {iconStart}
          </WrapperIconStart>
        )}
        <Input type={type === 'password' ? typeInput : type} placeholder={placeholder} maxLength={maxLength} onChange={onChange} onFocus={onFocus} onBlur={onBlur} onKeyUp={onKeyUp} value={value} />
        {type === 'password' && (
          <WrapperIconEnd onClick={handleClickVisible}>
            {visible ? (<VisibleOffIcon color={colors.grayLight} />) : (<VisibleOnIcon color={colors.grayLight} />)}
          </WrapperIconEnd>
        )}
      </Wrapper>
    </FieldWrapper>
  )
}
