import { getEndpointWithBaseUrl } from "api/endpoints";
import CustomLayer from "components/control/CustomLayer";
import { useGlobalContext } from "state/Context";

const PlanificacionLayer = () => {
  // const sliderControl= L.Control.Scale
  const {
    state: {
      appState: { mapPlanificacion },
    },
  } = useGlobalContext();

  return (
    <>
      {mapPlanificacion.layers.map((element, index) => {
        const url = getEndpointWithBaseUrl(element.base, "wms");
        return (
          <CustomLayer
            key={element.id}
            index={index}
            currentLayer={element}
            url={url}
          />
        );
      })}
    </>
  );
};

export default PlanificacionLayer;
