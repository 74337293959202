const SET_APP_STATE = "SET_APP_STATE";
const SET_CONNECTIVITY_CHANGE = "SET_CONNECTIVITY_CHANGE";

const UPDATE_IDENTIFIER = "UPDATE_IDENTIFIER";
const UPDATE_LOCATION = "UPDATE_LOCATION";
const UPDATE_UTM_CONVERT = "UPDATE_UTM_CONVERT";

export const updateIdentifier = (payload: boolean) => ({
  type: UPDATE_IDENTIFIER,
  payload,
});

export const updateLocation = (payload: boolean) => ({
  type: UPDATE_LOCATION,
  payload,
});

export const updateUtmConvert = (payload: boolean) => ({
  type: UPDATE_UTM_CONVERT,
  payload,
});


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  SET_APP_STATE,
  SET_CONNECTIVITY_CHANGE,
  UPDATE_IDENTIFIER,
  UPDATE_LOCATION,
  UPDATE_UTM_CONVERT,
};
