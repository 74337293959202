import { FC } from "react";
import Control from "react-leaflet-custom-control";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import {
  Fullscreen as FullscreenIcon,
  LocationSearchingOutlined as LocationIcon,
  MapOutlined as MapIcon,
  NearMeOutlined as IdentifierIcon,
  GpsFixedOutlined as GpsIcon,
  WhereToVoteOutlined as SuccessIdentifierIcon,
  Search as SearchIcon
} from "@mui/icons-material";
import { useGlobalContext } from "state/Context";
import { CENTER_POINT } from "pages/constants";
import Actions, { updateUtmConvert } from "state/Actions";
import { useCurrentState } from "context/StateContext";
import { useMap } from "react-leaflet";
import { CgPinAlt } from "react-icons/cg";

interface IToolbarProps {

}

const Toolbar: FC<IToolbarProps> = () => {
  const {
    state: {
      appState: {
        tools: { isIdentifier, isLocation, isUtmConvert },
      },
    },
    dispatch,
  } = useGlobalContext();
  const { currentPredio, setCurrentPredio } = useCurrentState();
  const map = useMap()
    ;
  const handleClickFullExtent = () => {
    if (map) {
      map.flyTo(CENTER_POINT, 14);
    }
  };

  const handleClickIdentifier = () => {
    dispatch({ type: Actions.UPDATE_IDENTIFIER, payload: !isIdentifier });
  };

  const handleClickLocation = () => {
    if (!isLocation) {
      dispatch({ type: Actions.UPDATE_LOCATION, payload: true });
    }
  };

  const handleClickZoomToSelectedFeature = () => {
    if (map && currentPredio) {
      const { positions } = currentPredio;
      map.flyToBounds(positions!,{maxZoom:18});
    }
  };

  const handleClickUtmConvert = () => {
    dispatch(updateUtmConvert(!isUtmConvert));
  };

  return (
    <Control position="topleft">
      <ButtonGroup orientation="vertical" variant="contained">
        <Tooltip placement="left" title="Seleccionar característica">
          <Button
            color={isIdentifier ? "info" : "inherit"}
            onClick={() => handleClickIdentifier()}
            variant="contained"
          >
            <IdentifierIcon />
          </Button>
        </Tooltip>
        <Tooltip placement="left" title="Localización actual">
          <Button
            color={"inherit"}
            onClick={() => handleClickLocation()}
            variant="contained"
          >
            <GpsIcon />
          </Button>
        </Tooltip>
        <Tooltip placement="left" title="Zoom a la característica seleccionada">
          <Button
            color={"inherit"}
            onClick={() => handleClickZoomToSelectedFeature()}
            variant="contained"
          >
            <SuccessIdentifierIcon />
          </Button>
        </Tooltip>
        <Tooltip placement="left" title="Centrar mapa">
          <Button
            color={"inherit"}
            onClick={() => handleClickFullExtent()}
            variant="contained"
          >
            <FullscreenIcon />
          </Button>
        </Tooltip>
        <Tooltip placement="left" title="Mostrar coordenadas UTM">
          <Button
            color={isUtmConvert ? "info" : "inherit"}
            onClick={() => handleClickUtmConvert()}
            variant="contained"
          >
            <CgPinAlt style={{ width: 24, height: 24 }} />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Control>
  )
};

export default Toolbar;
