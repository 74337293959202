import { colors } from "../../../theme/base";
import { SVGRProps } from "../interfaces";

const VisibleOffIcon = ({ color = colors.black }: SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={13} fill="none">
  <path
    d="m8.269 8.938-2.54-1.981a2.452 2.452 0 0 0 2.413 1.98h.127Zm-.127 1.218a3.636 3.636 0 0 1-3.656-3.63c0-.153.025-.33.025-.483L2.074 4.088c-.457.61-.838 1.27-1.168 1.98-.051.127-.102.305-.102.432s.025.33.076.457c1.371 3.123 4.114 5.23 7.237 5.23 1.142 0 2.234-.304 3.25-.837L9.462 9.877c-.406.203-.863.28-1.32.28Zm7.871 1.778-2.615-2.082a9.379 9.379 0 0 0 1.955-2.895c.05-.102.076-.305.076-.432a1.29 1.29 0 0 0-.076-.431C13.982 2.97 11.24.864 8.142.864c-1.6 0-3.098.558-4.342 1.523L.982.152A.501.501 0 0 0 .626 0a.578.578 0 0 0-.482.254.561.561 0 0 0 .101.838l15.006 11.756a.561.561 0 0 0 .838-.102c.254-.228.203-.61-.076-.812ZM11.773 6.5c0 .635-.178 1.219-.457 1.752l-.99-.762c.127-.304.228-.635.228-.99a2.455 2.455 0 0 0-2.437-2.438c-.051 0-.127.026-.204.026.127.228.204.508.204.787 0 .28-.077.508-.178.736L5.679 3.81c.66-.584 1.498-.965 2.463-.965 2.006 0 3.63 1.65 3.63 3.656Z"
    fill={color}
  />
</svg>
)

export default VisibleOffIcon;



