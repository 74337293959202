import axios, {
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { BASE_URL } from "./endpoints";

const defaultHeaders = {
  //'Content-Type': 'application/json; charset=utf-8',
  //'x-version': '2',
  // 'fms-mobile-app-os': deviceOsName(),
  // 'fms-mobile-app-version': PLATFORM_VERSION,
};

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: defaultHeaders,
});

const callApi = async <D = any>(
  config: AxiosRequestConfig,
  baseUrl?: string
): Promise<AxiosResponse<D> | { data: null; status: 0 }> => {
  try {
    axiosInstance.defaults.baseURL = baseUrl || BASE_URL;

    const headers = config.headers || {};

    // console.log("config: ", { config }, " headers: ", {headers});

    const response = await axiosInstance.request({
      ...config,
      headers,
    });
    console.log("config: ", { config }, " headers: ", {headers}," response: ", {response});
    return response;
  } catch (err) {
    console.log('error: ', {err});
    if (axios.isAxiosError(err)) {
      console.log(err.response?.data)
      if (!err?.response) {
        console.log("No Server Response");
      } else if (err.response?.status === 400) {
        console.log("Missing Username or Password");
      } else if (err.response?.status === 401) {
        console.log("Unauthorized");
      } else {
        console.log("Login Failed");
      }
    }
  }
  return {
    data: null,
    status: 0,
  };
};

export default callApi;
