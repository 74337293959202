import { colors } from "theme/base";
import { SVGRProps } from "../interfaces";

const KeyPasswordIcon = ({ color = colors.black }: SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <path
      d="M9.844.5a5.145 5.145 0 0 0-5.156 5.156c0 .44.058.85.146 1.26l-4.57 4.57A.928.928 0 0 0 0 12.16v2.871c0 .264.205.469.469.469H3.28a.482.482 0 0 0 .469-.469v-1.406h1.406a.482.482 0 0 0 .469-.469V11.75h1.67a.44.44 0 0 0 .322-.117l.967-.967c.41.088.82.146 1.26.146A5.164 5.164 0 0 0 15 5.657 5.145 5.145 0 0 0 9.844.5Zm1.172 5.156c-.674 0-1.172-.498-1.172-1.172 0-.644.498-1.171 1.172-1.171.644 0 1.171.527 1.171 1.171 0 .674-.527 1.172-1.171 1.172Z"
      fill={color}
    />
  </svg>
);

export default KeyPasswordIcon;
