import { colors } from "../../../theme/base";
import { SVGRProps } from ".././interfaces";

const SaveIcon = ({ color = colors.black }: SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none">
    <path
      d="m12.685 2.871-2.46-2.46C10.02.204 9.58 0 9.258 0H1.875A1.87 1.87 0 0 0 0 1.875v9.375c0 1.055.82 1.875 1.875 1.875h9.375a1.87 1.87 0 0 0 1.875-1.875V3.867c0-.322-.205-.762-.44-.996ZM6.562 11.25a1.851 1.851 0 0 1-1.875-1.875A1.87 1.87 0 0 1 6.563 7.5c1.026 0 1.875.85 1.875 1.875a1.87 1.87 0 0 1-1.874 1.875Zm2.813-6.094a.482.482 0 0 1-.469.469H2.344a.463.463 0 0 1-.469-.469V2.344c0-.235.205-.469.469-.469h6.562c.235 0 .469.234.469.469v2.812Z"
      fill={color}
    />
  </svg>
);

export default SaveIcon;
