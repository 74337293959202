import { Box, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useCurrentState } from "context/StateContext";
import SearchBar from "material-ui-search-bar";
import { FC, useState } from "react";
import styled from "styled-components";
import { mutationPredio } from "util/Array";
import { IPredioFeature, IPredioProperties } from "util/interfaces";

const P = styled.p`
  margin: 0;
  padding: 0;
`;

const S = styled.span`
  
`;

const H2 = styled.h2`

`;

interface IFeatureInfoProps { 
  feature: IPredioProperties;
}
interface food {
  name: string;
  calories: number;
  fat: number;
  carbs: number;
  protein: number;
}
const originalRows: food[] = [
  { name: "Pizza", calories: 200, fat: 6.0, carbs: 24, protein: 4.0 },
  { name: "Hot Dog", calories: 300, fat: 6.0, carbs: 24, protein: 4.0 },
  { name: "Burger", calories: 400, fat: 6.0, carbs: 24, protein: 4.0 },
  { name: "Hamburger", calories: 500, fat: 6.0, carbs: 24, protein: 4.0 },
  { name: "Fries", calories: 600, fat: 6.0, carbs: 24, protein: 4.0 },
  { name: "Ice Cream", calories: 700, fat: 6.0, carbs: 24, protein: 4.0 }
];

const FeatureInfo: FC<IFeatureInfoProps> = ({ feature }) => {
// const FeatureInfo: FC<object> = (feature) => {
  const [searched, setSearched] = useState<string>("");
  const prediosManzano: IPredioFeature[] = [
    {type: "Feature", id: "predios.19520", geometry: {type:"MultiPolygon",coordinates:[[[[-66.24961173,-17.40386693],[-66.24942873,-17.40389043],[-66.24942873,-17.40389043],[-66.24943605,-17.40426422],[-66.24943605,-17.40426422],[-66.25133296,-17.40414122],[-66.25133296,-17.40414122],[-66.2513502,-17.40361861],[-66.2513502,-17.40361861],[-66.25047208,-17.40375512],[-66.25047208,-17.40375512],[-66.25032867,-17.40377759],[-66.25032867,-17.40377759],[-66.24982074,-17.40384009],[-66.24982074,-17.40384009],[-66.24961173,-17.40386693]]]]},geometry_name:"geom",positions:undefined,properties:{objectid:19520,dist:"08",codigo:null,codsist:"080025049",shape_leng:507.989734678,shape_area:9908.01124103}},
    {"type":"Feature","id":"predios.19510","geometry":{"type":"MultiPolygon","coordinates":[[[[-66.25138527,-17.40470586],[-66.25139077,-17.40419917],[-66.25139077,-17.40419917],[-66.24953399,-17.40431957],[-66.24952897,-17.40465477],[-66.24955446,-17.40483358],[-66.24955446,-17.40483358],[-66.25138527,-17.40470586]]]]},"geometry_name":"geom",positions:undefined,"properties":{"objectid":19510,"dist":"08","codigo":"17813","codsist":"080025012","shape_leng":506.272551286,"shape_area":11146.5151494}},
    {"type":"Feature","id":"predios.19508","geometry":{"type":"MultiPolygon","coordinates":[[[[-66.25127989,-17.4058353],[-66.25130445,-17.40548788],[-66.25130445,-17.40548788],[-66.25132812,-17.40529176],[-66.25132812,-17.40529176],[-66.25138527,-17.40470586],[-66.25138527,-17.40470586],[-66.24955446,-17.40483358],[-66.24955446,-17.40483358],[-66.24955244,-17.40496874],[-66.24954559,-17.40504976],[-66.24960437,-17.40554938],[-66.24962182,-17.40576863],[-66.24962182,-17.40576863],[-66.25098412,-17.40582341],[-66.25098412,-17.40582341],[-66.25127989,-17.4058353]]]]},"geometry_name":"geom",positions:undefined,"properties":{"objectid":19508,"dist":"08","codigo":"5349","codsist":"080025013","shape_leng":601.225248744,"shape_area":21265.1973592}},
    {"type":"Feature","id":"predios.19495","geometry":{"type":"MultiPolygon","coordinates":[[[[-66.25179872,-17.40525577],[-66.25190392,-17.4046931],[-66.25190392,-17.4046931],[-66.25172009,-17.40468924],[-66.25138527,-17.40470586],[-66.25138527,-17.40470586],[-66.25132812,-17.40529176],[-66.25132812,-17.40529176],[-66.25179872,-17.40525577]]]]},"geometry_name":"geom","properties":{"objectid":19495,"dist":"08","codigo":"18965","codsist":"080025008","shape_leng":233.871159485,"shape_area":3347.05405505}},
    {"type":"Feature","id":"predios.19496","geometry":{"type":"MultiPolygon","coordinates":[[[[-66.25132812,-17.40529176],[-66.25130445,-17.40548788],[-66.25130445,-17.40548788],[-66.25178715,-17.40547462],[-66.25178715,-17.40547462],[-66.25179872,-17.40525577],[-66.25179872,-17.40525577],[-66.25132812,-17.40529176]]]]},"geometry_name":"geom","properties":{"objectid":19496,"dist":"08","codigo":"4348","codsist":"080025007","shape_leng":147.672568269,"shape_area":1159.43247608}},
    {"type":"Feature","id":"predios.19514","geometry":{"type":"MultiPolygon","coordinates":[[[[-66.25178987,-17.40584764],[-66.25178687,-17.40577061],[-66.25178687,-17.40577061],[-66.25178715,-17.40547462],[-66.25178715,-17.40547462],[-66.25130445,-17.40548788],[-66.25130445,-17.40548788],[-66.25127989,-17.4058353],[-66.25127989,-17.4058353],[-66.25178987,-17.40584764]]]]},"geometry_name":"geom","properties":{"objectid":19514,"dist":"08","codigo":"8467","codsist":"080025006","shape_leng":185.465343797,"shape_area":2099.88736939}}
  ];
  const [colPredios, setRows] = useState<IPredioFeature[]>(prediosManzano);
  const requestSearch = (searchedVal: string) => {
    const filteredRows = prediosManzano.filter((row:IPredioFeature) => {
      return row.properties.codsist.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  const [selectedRow, setSelectedRow] = useState<IPredioFeature>();
  const { setCurrentPredio } = useCurrentState();
  let cont: number=0;
  if(selectedRow && cont===0){
    console.log('contCero: ', cont);
    console.log('click en: ',selectedRow?.properties.codsist);
    // setCurrentPredio(mutationPredio(selectedRow));
    cont++;
  }else{
    console.log('cont: ', cont);
    cont=0;
  }
  // console.log('click en: ',{ selectedRow });
  // console.log('el feature: ', feature);
  if (!feature){ return null } else {
    // if (feature)
    // const convert = JSON.parse(String(feature));
    const { objectid, dist, codigo, codsist, shape_area, shape_leng, nombre, CODIFICACI, OBJECTID, Shape_Area, Shape_Leng } = feature;
    console.log('tam objectid: ', objectid);
    if(objectid!=undefined && dist!=null){
      return (
        <>
          <H2>Predio: {codsist}</H2>
          <P>{'Object ID:'} <S>{objectid}</S></P>
          <P>{'Dist: '} <S>{dist}</S></P>
          <P>{'Codigo: '} <S>{codigo}</S></P>
          <P>{'Cod Sist: '} <S>{codsist}</S></P>
          <P>{'Area: '} <S>{shape_area}</S></P>
          <P>{'Perimetro: '} <S>{shape_leng}</S></P>
        </>
      )
    }else if(nombre!=null){
      return (
        <>
          <H2>Vía: {nombre}</H2>
        </>
      )
    }else{
      return (
            <>
              <H2>Manzano: {CODIFICACI}</H2>
              <P>{'Object ID:'} <S>{OBJECTID}</S></P>
              <P>{'Area: '} <S>{Shape_Area}</S></P>
              <P>{'Perimetro: '} <S>{Shape_Leng}</S></P>
              {/* <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                <SearchBar
                  value={searched}
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                  placeholder="Cod. Sis."
                />
              </Box>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Cod. Sist.</TableCell>
                      <TableCell align="right">Distrito</TableCell>
                      <TableCell align="right">Área</TableCell>
                      <TableCell align="right">Perímetro</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {colPredios.map((predio: IPredioFeature) => (
                      <TableRow 
                        onClick={() => setSelectedRow(predio)}
                        key={predio.properties.codsist}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {predio.properties.codsist}
                        </TableCell>
                        <TableCell align="right">{predio.properties.dist}</TableCell>
                        <TableCell align="right">{predio.properties.shape_area}</TableCell>
                        <TableCell align="right">{predio.properties.shape_leng}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> */}
            </>
          )
    }
  }
}

export default FeatureInfo;
