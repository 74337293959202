import * as utm from "utm";
import { useState } from "react";
import { useMapEvents } from "react-leaflet";
import { useGlobalContext } from "state/Context";
import styled from "styled-components";
import { hexToRGB } from "util/Color";
import { colors } from "theme/base";

const P = styled.p`
  border-radius: 10px;
  color: ${colors.white};
  font-size: 1rem;
  font-weight: bold;
  zIndex: 900;
  background: ${hexToRGB(colors.primary, '.5')};
  padding: 2px 8px;
`;

interface IUtmCoodinate {
  easting: number;
  northing: number;
  zoneLetter: string;
  zoneNum: number;
}

const DisplayPosition = () => {
  const {
    state: {
      appState: {
        tools: { isUtmConvert },
      },
    }
  } = useGlobalContext();
  const [position, setPosition] = useState<IUtmCoodinate | null>(null);

  useMapEvents({
    mousemove(event) {
      if (isUtmConvert) {
        const { lat, lng } = event.latlng;

        const result = utm.fromLatLon(lat, lng);
        // const result2 = utm.toLatLon(
        //   result.easting,
        //   result.northing,
        //   result.zoneNum,
        //   result.zoneLetter
        // );
        // console.log("location result:", { result });
        // console.log("location result2:", { result2 });
        // console.log("location UTM LAST:", { outputCoords });
        setPosition(result);
      }
    },
    mouseout() {
      setPosition(null);
    },
  });

  //if (!position) return <></>;

  if (!isUtmConvert || !position) return <></>;

  return (
    <P style={{ zIndex: 900 }}>
      {`${position?.easting.toFixed(4)}, ${position?.northing.toFixed(4)} ${position?.zoneNum} ${position?.zoneLetter}`}
    </P>
  );
};

export default DisplayPosition;
