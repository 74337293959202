import { callCatalog } from "api/features";
import { FC, useCallback, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { ColumnsHeader } from "./constants";
import { useAuthentication } from "context/AuthenticationContext";

interface ICatalogRow {
  id: number;
  nombreCap: string;
  wms: string;
  wfs: string;
}

const Catalog: FC = () => {
  const { userInfo } = useAuthentication();
  const [data, setData] = useState<ICatalogRow | []>([]);

  const getCatalog = useCallback(async (id: number) => {
    const result = await callCatalog(id);
    const mappedData = result.map((item: { ID_CATALOGO: number; NOMBRE_CAPA: string; URL_WMS: string; URL_WFS: string; }) => ({
      id: item.ID_CATALOGO,
      nombreCapa: item.NOMBRE_CAPA,
      wms: item.URL_WMS,
      wfs: item.URL_WFS,
    }));
    setData(mappedData);
    // console.log('CATALOG info: ', { result })
  }, [])

  useEffect(() => {
    if (userInfo?.id) {
      getCatalog(userInfo.id);
    }
  }, [getCatalog, userInfo?.id]);

  if (Object.entries(data).length !== 0) {
    return (
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={data as any[]}
          columns={ColumnsHeader}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    );
  }
  return <></>;

};

export default Catalog;