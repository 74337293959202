import styled from "styled-components";
import { colors, fonts } from "theme/base";

export const P = styled.p`
  font-size: 1rem;
  font-family: ${fonts.medium};
  color: ${colors.grayLight};
  padding: 0 0 0 0.5rem;
  width: 350px;
  margin: 0.5rem 0;
`;

export const Layout = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;
`;