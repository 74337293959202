import { useState } from "react";
import { IBookmark, IMapBookmarks } from "util/interfaces";

const BOOKMARKS_STORAGE_KEY = "bookmarks";

export const useBookmarks = (mapId: number) => {
  const [bookmarks, setBookmarks] = useState<IBookmark[]>(() => {
    const storedBookmarks = localStorage.getItem(BOOKMARKS_STORAGE_KEY);
    if (storedBookmarks) {
      const parsedBookmarks = JSON.parse(storedBookmarks) as IMapBookmarks[];
      const mapBookmarks = parsedBookmarks.find((map) => map.map === mapId);
      if (mapBookmarks) {
        return mapBookmarks.bookmarks;
      }
    }
    return [];
  });

  const addBookmark = (bookmark: IBookmark) => {
    const mapBookmarks = localStorage.getItem(BOOKMARKS_STORAGE_KEY);
    let bookmarksToStore: IMapBookmarks[];
    if (mapBookmarks) {
      bookmarksToStore = JSON.parse(mapBookmarks) as IMapBookmarks[];
      const index = bookmarksToStore.findIndex((map) => map.map === mapId);
      if (index !== -1) {
        bookmarksToStore[index].bookmarks.push(bookmark);
      } else {
        bookmarksToStore.push({ map: mapId, bookmarks: [bookmark] });
      }
    } else {
      bookmarksToStore = [{ map: mapId, bookmarks: [bookmark] }];
    }
    localStorage.setItem(BOOKMARKS_STORAGE_KEY, JSON.stringify(bookmarksToStore));
    setBookmarks((prevBookmarks) => [...prevBookmarks, bookmark]);
  };

  return { bookmarks, addBookmark };
};
