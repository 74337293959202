
import { FC, ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { colors, fonts } from "theme/base";
import CircularProgress from '@mui/material/CircularProgress';

type TButton = 'submit' | 'button';
type TVariant = 'contained' | 'outlined' | 'secondary';

const getFillColor = (value: TVariant) => {
  switch (value) {
    case 'outlined':
      return colors.white;
    case 'secondary':
      return colors.secondary;
    default:
      return colors.primary;
  }
};

const getTextColor = (value: TVariant) => {
  switch (value) {
    case 'outlined':
      return colors.primary;
    default:
      return colors.white;
  }
};

const getBorderColor = (value: TVariant) => {
  switch (value) {
    case 'secondary':
      return colors.secondary;
    default:
      return colors.primary;
  }
};

const StyledButton = styled.button<{ variant: TVariant; width: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  //font-family: ${fonts.semiBold};
  background-color: ${({ variant }) => getFillColor(variant)};
  border: 0.1rem solid  ${({ variant }) => getBorderColor(variant)};
  color: white;
  font-size: 0.9rem;
  padding: 0.2rem 0.8rem;
  border-radius: 8px;
  margin: 10px 0px;
  cursor: pointer;
  width: ${({ width }) => width ? width : 'auto'};
  height: 3rem;
  :hover {
    // background-color: ${colors.fail};
    // border: 0.1rem solid ${colors.fail};
  };
  :disabled {
    background-color: ${colors.grayLight};
    border: 0.1rem solid ${colors.grayLight};
  }
`;

const WrapperIcon = styled.div`
  display: flex;
  margin: 0;
`;

const Label = styled.span<{ variant: TVariant }>`
  color: ${({ variant }) => getTextColor(variant)};
  margin: 0 0 0 0.3rem;
`;

export interface IButtonProps {
  text: string;
  icon?: ReactNode;
  loading?: boolean;
  type?: TButton;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: TVariant;
  disabled?: boolean;
  width?: string;
}

export const Button: FC<IButtonProps> = ({ text, icon, loading = false, type = 'submit', onClick, variant = 'contained', disabled = false, width = 'auto' }): ReactElement => {
  return (
    <StyledButton type={type} onClick={onClick} disabled={disabled} variant={variant} width={width}>
      {loading ? <CircularProgress size="1rem" sx={{ color: colors.white }} /> : <WrapperIcon>
        {icon && <div>{icon}</div>}
        <Label variant={variant}>
          {text}
        </Label>
      </WrapperIcon>}
    </StyledButton>
  )
}
