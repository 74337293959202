import callApi from "./callApi";
import { BASE_URL_AUTH, ENDPOINTS, getEndpoint } from "./endpoints";

export type TLayers = "manzanos" | "predios" | "vias";

export type TEpsg = "EPSG:4326" | "EPSG:32719";

export interface IPredioInfoProps {
  bbox: string;
  height: string;
  i: string;
  j: string;
  width: string;
}

export const getFeaturePredioInfo = async ({
  bbox,
  height,
  i,
  j,
  width,
}: IPredioInfoProps) => {
  const body = {
    BBOX: bbox,
    CRS: "EPSG:4326",
    FORMAT: "image/png",
    HEIGHT: height,
    I: i,
    INFO_FORMAT: "application/json",
    J: j,
    LAYERS: "predios",
    QUERY_LAYERS: "predios",
    REQUEST: "GetFeatureInfo",
    SERVICE: "WMS",
    VERSION: "1.3.0",
    WIDTH: width,
    EXCEPTIONS: "application/vnd.ogc.se_xml",
  };
  const url = getEndpoint(ENDPOINTS.PREDIOS, "wms");

  const response = await callApi({
    url,
    method: "get",
    params: body,
  });

  return response.data;
};

export const getAllFeaturePredioInfo = async () => {
  const body = {
    request: "GetFeature",
    service: "wfs",
    typeNames: "namespace:predios",
    version: "2.0.0",
    outputFormat: "json",
    srsName: "urn:ogc:def:crs:EPSG::4326",
  };

  const url = getEndpoint(ENDPOINTS.PREDIOS, "wfs");
  const response = await callApi({
    url,
    method: "get",
    params: body,
  });

  return response.data;
};

export const getFeatureManzanoInfo = async () => {
  const body = {
    REQUEST: "GetFeatureInfo",
    VERSION: "1.3.0",
    SERVICE: "WMS",
    CRS: "EPSG:32719",
    WIDTH: "600",
    HEIGHT: "600",
    LAYERS: "manzanos",
    EXCEPTIONS: "application/vnd.ogc.se_xml",
    BBOX: "791522.625,8071020.0,796766.125,8079632.5",
    QUERY_LAYERS: "manzanos",
    INFO_FORMAT: "application/json",
    I: "240",
    J: "400",
  };

  const url = getEndpoint(ENDPOINTS.MANZANAS, "wms");

  const response = await callApi({
    url,
    method: "get",
    params: body,
  });

  return response.data;
};

export const getLegendLayer = async (endpoint: string, layer: string) => {
  const body = {
    request: "GetLegendGraphic",
    service: "WMS",
    format: "image/png",
    width: "20",
    height: "20",
    layer,
  };

  // const url = getEndpoint(ENDPOINTS.PLANIFICACION, "ows");
  const url = getEndpoint(endpoint, "ows");
  const response = await callApi({
    url,
    method: "get",
    params: body,
    responseType: "blob",
  });

  return response.data;
};

export const callCatalog = async (id: number) => {
  const url = `${ENDPOINTS.CATALOG}/user_id=${id}`;
  const response = await callApi({ url, method: "get" }, BASE_URL_AUTH);
  return response.data;
};
