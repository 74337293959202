enum RoutePath {
  All = '/*',
  Root = '/',
  Home = '/inicio',
  Catalog = '/catalogo',
  Urbanismo = '/urbanismo',
  Planificacion = '/planificacion',
  SignIn = '/signin',
  UpdatePassword = '/updatePassword'
}

export default RoutePath;
