import { useCallback } from "react";

import { useChangePasswordMutation } from "api/hooks/useChangePasswordMutation";
import { IChangePasswordVariables } from "api/Authentication";

interface IUseChangePasswordProps {
  onSuccess: () => void;
  onError: (error: unknown) => void;
}

export const useChangePassword = ({
  onSuccess,
  onError,
}: IUseChangePasswordProps) => {
  const { mutate: _changePassword, isLoading } = useChangePasswordMutation({
    onSuccess: onSuccess,
    onError: (error: unknown) => {
      onError(error);
    },
  });

  const updatePassword = useCallback(
    async ({
      token,
      newPassword,
      reNewPassword,
      currentPassword,
    }: IChangePasswordVariables) => {
      try {
        _changePassword({
          token,
          newPassword,
          reNewPassword,
          currentPassword,
        });
      } catch (error) {}
    },
    [_changePassword]
  );

  return {
    updatePassword,
    isLoading,
  };
};
