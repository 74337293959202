import RoutePath from "../RoutePath";
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  P,
  H1,
} from "./NavbarElements";
import Logo from 'components/assets/Logo';
import labels from "constants/labels.json";
import UpdatePassword from "pages/login/UpdatePassword";
import { useAuthentication } from "context/AuthenticationContext";

const { HomePage: PageLabels } = labels;

const Navbar = () => {
  const { userInfo } = useAuthentication();

  return (
    <>
      <Nav>
        <Bars />
        <NavMenu>
          <NavLink to="/">
            <Logo />
            <H1>{PageLabels.navbar.title}</H1>
          </NavLink>
          {/* <NavLink to={RoutePath.Home}>
            <BuildingsIcon />
            Inicio
          </NavLink> */}
          <NavLink to={RoutePath.Planificacion}>
            {/* <ResidentsIcon /> */}
            Planificacion
          </NavLink>
          <NavLink to={RoutePath.Urbanismo}>
            {/* <BuildingsIcon /> */}
            Urbanismo
          </NavLink>
          {/* <NavLink to={RoutePath.Catalog}>
            Catalogo
          </NavLink> */}
        </NavMenu>
        {/* <NavBtn> */}
          {/* <P>{`Bienvenid@ ${userInfo?.username}`}</P> */}
          {/* <UserSelect text={currentUser?.fullName ? currentUser?.fullName : 'N/A'} /> */}
        {/* </NavBtn> */}
        <NavBtn>
          <UpdatePassword />
        </NavBtn>
      </Nav>
    </>
  );
};

export default Navbar;
