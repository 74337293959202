import React, { FC, useEffect, useState } from "react";
import {
  LayersControl,
  MapContainer,
  TileLayer,
  ScaleControl,
  WMSTileLayer,
  ZoomControl,
} from "react-leaflet";
import { LatLngLiteral, Map } from "leaflet";
import {
  CENTER_POINT,
  ESRI_WORLD_IMAGERY_LAYER,
  IMAGEN_2022,
  OPEN_STREET_LAYER,
} from "pages/constants";
import labels from "constants/labels.json";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DisplayPosition from "components/control/DiplayPosition";
import CustomMarker from "components/control/CustomMarker";
import Control from "react-leaflet-custom-control";
import { useCurrentState } from "context/StateContext";
import { useGlobalContext } from "state/Context";
import LocationMarker from "components/control/LocationMarker";
import { IBookmark, IPredioFeature } from "util/interfaces";
import { mutationPredio } from "util/Array";
import { useFeature } from "hooks/useFeature";
import "leaflet/dist/leaflet.css"
import Toolbar from "components/Toolbar";
import { useCurrentBookmarks } from "context/BookmarksContext";
import BookmarkControl from "components/BookmarkControl/BookmarkControl";

interface IState {
  currentLocation: LatLngLiteral;
  zoom: number;
}

const { MapViewPage: PageLabels } = labels;
const { BaseLayer } = LayersControl;

const Home: FC = () => {
  const {
    state: {
      appState: {
        tools: { isLocation },
      },
    },
    dispatch,
  } = useGlobalContext();
  const  { addBookmark } = useCurrentBookmarks()
  const { currentPredio, setCurrentPredio } = useCurrentState();
  const [map, setMap] = useState<Map | null>(null);
  const { data } = useFeature();
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const [value, setValue] = useState("");
  const [state, setState] = useState<IState>({
    currentLocation: CENTER_POINT,
    zoom: 13.5,
  });

  useEffect(() => {
    toast.info("Bienvenid@", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "colored",
    });
  }, []);

  const handleLogout = () => {
    navigate("/", { replace: false });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (value.length === 9) {
      const found = data.features.find(
        (element: IPredioFeature) => element.properties.codsist === value
      );
      if (found) {
        setCurrentPredio(mutationPredio(found));
      }
    }
  };

  const handleMapClick = (event: L.LeafletMouseEvent) => {
    const { latlng } = event;
    const bookmark: IBookmark = {
      layerId: "my-layer",
      type: "marker",
      description: "My bookmark",
      data: [],
      position: [latlng.lat, latlng.lng],
    };
    addBookmark(bookmark);
  };

  const handlePolylineClick = (event: L.LeafletMouseEvent) => {
    const { latlng } = event;
    const bookmark: IBookmark = {
      layerId: "my-layer",
      type: "polyline",
      description: "My polyline",
      data: [[latlng.lat, latlng.lng], [latlng.lat + 0.01, latlng.lng + 0.01]],
    };
    addBookmark(bookmark);
  };

  const handlePolygonClick = (event: L.LeafletMouseEvent) => {
    const { latlng } = event;
    const bookmark: IBookmark = {
      layerId: "my-layer",
      type: "polygon",
      description: "My polygon",
      data: [
        [
          [latlng.lat, latlng.lng],
          [latlng.lat + 0.01, latlng.lng],
          [latlng.lat + 0.01, latlng.lng + 0.01],
          [latlng.lat, latlng.lng + 0.01],
        ],
      ],
    };
    addBookmark(bookmark);
  };
  // console.log("OTKEN HOME PAGE: ", {userDetails} )
  return (
    <div>
      <ToastContainer autoClose={1500} />
      <MapContainer
        zoomControl={false}
        attributionControl={true}
        center={state.currentLocation}
        // minZoom={0}
        // maxZoom={20}
        zoom={state.zoom}
        boundsOptions={{ animate: false }}
        whenReady={() => {
          setIsReady(true);
          console.log("READY");
        }}
        ref={(e) => setMap(e)}
      >
        {isLocation && <LocationMarker />}
        {isReady && (
          <Control prepend position="bottomright">
            <DisplayPosition />
          </Control>
        )}

        <Toolbar />

        <ZoomControl position="bottomleft" />

        <CustomMarker />
        <LayersControl collapsed={false} position="topright">
          <BaseLayer name={"Imagen 2022"}>
            <WMSTileLayer
              // maxNativeZoom={21}
              maxZoom={25} // puede mas pero se recomienda 23
              url={IMAGEN_2022.url}
              params={{ layers: "imagen2022", format: "image/png" }}
              eventHandlers={{
                unload: (event) => {
                  console.log("UN LOAD ", event);
                },
                load: (event) => {
                  console.log("LOAD ", event);
                },
              }}
            />
          </BaseLayer>
          <BaseLayer checked name={PageLabels.toc.baseLayers.openStreetMap}>
            <TileLayer
              url={OPEN_STREET_LAYER.url}
              attribution={OPEN_STREET_LAYER.attribution}
              // maxNativeZoom={20}
              maxZoom={20} // 20 confirmado
            />
          </BaseLayer>
          <BaseLayer name={PageLabels.toc.baseLayers.esriWorldImagery}>
            <TileLayer
              url={ESRI_WORLD_IMAGERY_LAYER.url}
              attribution={ESRI_WORLD_IMAGERY_LAYER.attribution}
              //maxNativeZoom={29}
              maxZoom={19} //confirmado
            />
          </BaseLayer>
        </LayersControl>
        <ScaleControl position="bottomright" />
        <BookmarkControl />
        {/* <CustomDrawer /> */}
        {/* <Marker position={[0, 0]}>
          <Popup>
            <span>Click para agregar un marcador</span>
          </Popup>
        </Marker>
        <Polyline pathOptions={{ color: "red" }} positions={[[0, 0], [0, 5]]} eventHandlers={{ click: handlePolylineClick }} />
        <Polygon pathOptions={{ color: "blue" }} positions={[[[0, 0], [0, 1], [1, 1], [1, 0]]]} eventHandlers={{ click: handlePolygonClick }}  /> */}
      </MapContainer>
    </div>
  );
};

export default Home;
