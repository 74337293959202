import React, { useReducer } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./App.css";
import { StateProvider } from "context/StateContext";
import GlobalContext from "state/Context";
import { rootReducer } from "state/Reducers";
import initialState from "../src/state/State";
import Router from "pages";
import { BookmarksProvider } from "context/BookmarksContext";
import { AuthenticationProvider } from "context/AuthenticationContext";

function App() {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const queryClient = new QueryClient();
  console.log("STATE", { state });
  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <AuthenticationProvider>
        <StateProvider>
          <BookmarksProvider mapId={1}>
            <QueryClientProvider client={queryClient}>
              <Router />
            </QueryClientProvider>
          </BookmarksProvider>
        </StateProvider>
      </AuthenticationProvider>
    </GlobalContext.Provider>
  );
}

export default App;
