import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "theme/base";

type TJustifyContent = 'space-between' | 'flex-start' | 'flex-end';

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.skyblue1};
  height: 100vh;
  // justify-content: space-around;
  // align-items: center;
`;

export const Content = styled.div`
  display: flex;
  width: 100vw;
  background-color: transparent;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export const Wrapper = styled.div<{ width?: string }>`
  width: 600px;
  // height: 400px;
  margin: 0.8rem;
  padding: 45px;
  background-color: ${colors.white};
  border-radius: 10px;
  // overflow: hidden;
  box-shadow: 0px 0px 15px -5px;
  transition: 0.3s;
  animation: ease-in;
  :hover {
    transform: scale(1);
    box-shadow: 0px 0px 15px 0px;
  }
`;

export const ImageView = styled.div`
  display: flex;
  justify-content: center;
`;

export const Title = styled.div`
  color: ${colors.black};
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem 0 1rem 0;
  text-align: center;
`;

export const Form = styled.form`

`;

export const Divider = styled.div`
  border-bottom-color: ${colors.grayVeryLight};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin: 1em 0;
`;

export const WrapperBottom = styled.div<{ justifyContent: TJustifyContent }>`
  margin: 25px 0 0 0;
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
`;

export const StyledLink = styled(Link)`
  width: 100%;
  color: ${colors.primary};
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  text-align: right;
`;

export const StyledParagraph = styled.p`
  color: ${colors.grayLight};
  font-size: 1rem;
  margin-block: 0;
  margin-inline: 0;
`;