import L from "leaflet";
import { FC, useEffect } from "react";
import "./Legend.css";
import { useMap } from "react-leaflet";
import AccordionControl from "components/AccordionControl";
import styled from "styled-components";
import { createRoot } from "react-dom/client"

const Img = styled.img`
  display: flex;
`;

interface ILegendProps {
  data: Blob[];
}

const ImagesArray = ({ data }: { data: Blob[] }) => {
  return (
    <AccordionControl title="Leyenda">
      {data.map((element, index) => {
        const imgURl = URL.createObjectURL(element);
        return (
          <Img key={index} src={imgURl} alt={`index`} />
        )
      })}
    </AccordionControl>
  )
}

const Legend: FC<ILegendProps> = ({ data }) => {
  const map = useMap();

  useEffect(() => {
    //console.log('render legend');
    if (!map || data.length === 0) return;

    const legend = new L.Control({ position: "bottomright" });

    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "");

      const accordionContainer = L.DomUtil.create("div", "", div);
      accordionContainer.id = "accordion-container";
      const root = createRoot(accordionContainer);
      root.render(<ImagesArray data={data} />);
      // ReactDOM.render(<MyComponent data={<ImagesArray data={data} />} />, accordionContainer);
      return div;
    };

    legend.addTo(map);

    map.on('overlayadd', (event: any) => {
      console.log('overlayadd event', { event })
      // Aquí puedes agregar el código que deseas ejecutar en respuesta al evento overlayadd
    })

    map.on('overlayremove', (event: any) => {
      console.log('overlayremove event', { event })
      // Aquí puedes agregar el código que deseas ejecutar en respuesta al evento overlayadd
    })

    return () => {
      legend.remove();
    };
  }, [data, map]);
  // PARA VER LAS CARGAS QUE ESTAN ACTIVAS
  // map.eachLayer((layer: L.Layer) => {
  //   console.log('capa cargada: ', { layer })
  // })

  return null;
};

export default Legend;
