import React, { FC, ReactNode } from 'react';
// import { Accordion } from './Accordion';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListIcon from '@mui/icons-material/List';

interface IMyComponentProps {
  title?: string;
  children: ReactNode;
}

const AccordionControl: FC<IMyComponentProps> = ({ title, children }) => {
  return (
    <div>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ListIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {title && <Typography>{title}&nbsp;</Typography>}
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionControl;
