import { useQuery } from "@tanstack/react-query";
import { getAllFeaturePredioInfo } from "api/features";

export const useFeature = () => {
  const state = useQuery(
    ['getAllFeaturePredioInfo'],
    () => getAllFeaturePredioInfo(),
  );
  // const queryClient = useQueryClient();

  return state;
}
// export const useSearchPlace = (setValue: string): string => {
//   const { isLoading, error, data } = useQuery({
//     queryKey: ['repoData'],
//     queryFn: () =>
//       fetch('https://api.github.com/repos/TanStack/query').then(
//         (res) => res.json(),
//       ),
//   });
//   if (isLoading) return 'Loading...';

//   if (error) return 'An error has occurred: ' + error;

//   return JSON.stringify(data);
// }
