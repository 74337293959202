import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { IChangePasswordVariables, postChangePassword } from "api/Authentication";

interface IUseChangePasswordProps extends Omit<UseMutationOptions<unknown, unknown, IChangePasswordVariables, unknown>, 'mutationFn'> {
}

export const useChangePasswordMutation = (props: IUseChangePasswordProps = {
}) => {
  return useMutation((args) => postChangePassword(args), {
    mutationKey: ['postChangePassword'],
    ...props,
  });
};