import { paramsLayer, TMap } from "pages/constants";
import { FC } from "react";
import { LayersControl, WMSTileLayer } from "react-leaflet";
import { useGlobalContext } from "state/Context";
import { ILayer } from "state/interfaces";

const { Overlay } = LayersControl;

interface ICustomLayerProps {
  index: number;
  currentLayer: ILayer;
  url: string;
}

const CustomLayer: FC<ICustomLayerProps> = ({
  index,
  currentLayer,
  // layerWms,
  url,
}) => {
  const { base, name, layer } = currentLayer;

  return (
    <Overlay name={name}>
      <WMSTileLayer
        url={url}
        params={{ ...paramsLayer, layers: layer }}
        // attribution={attribution}
        eventHandlers={{
          click: () => {
            return console.log("EVENT: ");
          },
          unload: (event) => {
            console.log("UN LOAD ", event);
          },
          load: (event) => {
            console.log(`LOAD ${name}`, event);
            // getDataLegend();
          },
          remove: (event) => {
            console.log("remove ", event);
          },
          error: (event) => {
            console.log("error ", event);
          },
        }}
      />
    </Overlay>
  );
};

export default CustomLayer;
