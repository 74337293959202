import { colors } from "../../../theme/base";
import { SVGRProps } from "../interfaces";

const MailIcon = ({ color = colors.black }: SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={10} fill="none">
    <path
      d="M6.5 7.313c-.432 0-.863-.127-1.219-.407L0 2.793v5.738C0 9.217.533 9.75 1.219 9.75H11.78c.66 0 1.219-.533 1.219-1.219V2.793L7.693 6.906c-.355.28-.787.407-1.193.407ZM.406 2.082l5.383 4.19c.406.304.99.304 1.397 0l5.382-4.19c.254-.203.432-.533.432-.863C13 .559 12.441 0 11.781 0H1.22C.533 0 0 .559 0 1.219c0 .33.152.66.406.863Z"
      fill={color}
    />
  </svg>
)

export default MailIcon;



