
// http://3.217.17.20:8080/geoserver/Colcapirhua/manzanos/wms?REQUEST=GetFeatureInfo&VERSION=1.3.0&SERVICE=WMS&CRS=EPSG:32719&WIDTH=600&HEIGHT=600&LAYERS=manzanos&EXCEPTIONS=application/vnd.ogc.se_xml&BBOX=791522.625,8071020.0,796766.125,8079632.5&QUERY_LAYERS=manzanos&INFO_FORMAT=application/json&I=240&J=400
// http://3.217.17.20:8080/geoserver/catastro/imagen2022/wms
// http://3.217.17.20:8080/geoserver/Colcapirhua/predios/ows?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&BBOX=-17.3907273843652348,-66.2287726595302928,-17.39012196682934075,-66.22794402438832151&CRS=EPSG:4326&WIDTH=1032&HEIGHT=754&LAYERS=predios&STYLES=&FORMAT=image/png&QUERY_LAYERS=predios&INFO_FORMAT=application/json&I=632&J=531
// http://3.217.17.20:8080/geoserver/Colcapirhua/predios/ows?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&BBOX=-17.3907273843652348,-66.2287726595302928,-17.39012196682934075,-66.22794402438832151&CRS=EPSG:4326&WIDTH=1032&HEIGHT=754&LAYERS=predios&STYLES=&FORMAT=image/png&QUERY_LAYERS=predios&INFO_FORMAT=application/json&I=973&J=258
// http://3.217.17.20:8080/geoserver/Colcapirhua/predios/wfs
//  "http://3.217.17.20:8080/geoserver/catastro/imagen2022/wms"
//http://52.86.173.62:8000/auth/login/
// http://3.217.17.20:8080/geoserver/Planificacion/wms

export const BASE_URL = "http://3.217.17.20:8080";
export const BASE_URL_AUTH = "http://52.86.173.62:8001";

export type TService = 'wms' | 'wfs' | 'ows'

const WORKSPACE_DEFAULT = '/geoserver/Colcapirhua';
const WORKSPACE_PLANIFICACION = '/geoserver/Planificacion'
const WORKSPACE_URBANISMO = '/geoserver/Urbanismo'
const WORKSPACE_CATASTRO = '/geoserver/catastro'

export const ENDPOINTS = {
  PREDIOS: `${WORKSPACE_URBANISMO}/predios`,
  MANZANAS: `${WORKSPACE_DEFAULT}/manzanos`,
  VIAS: `${WORKSPACE_DEFAULT}/viasGeneral`,
  AREA_HOMOLOGADA: `${WORKSPACE_PLANIFICACION}/areaHomologadaUrbana`,
  AREAS_VERDES: `${WORKSPACE_URBANISMO}/areasVerdes`,
  EQUIPAMIENTOS_URBANOS: `${WORKSPACE_PLANIFICACION}/equipamientosUrbanos`,
  LIMITE_MUNICIPAL: `${WORKSPACE_PLANIFICACION}/limiteMunicipal`,
  LIMITES_DISTRITALES: `${WORKSPACE_PLANIFICACION}/limitesDistritales`,
  SERRANIA_KENAMARI: `${WORKSPACE_PLANIFICACION}/serraniaKenamari`,
  LOTES_PH: `${WORKSPACE_URBANISMO}/lotesPh`,
  IMAGEN_2022: `${WORKSPACE_CATASTRO}/imagen2022`,
  ZONAS_HOMOGENEAS: `${WORKSPACE_URBANISMO}/zonasHomogeneas`,
  AUTH: `/api-auth-djoser/token/login`,
  USER_INFO: '/api-auth-djoser/users/me',
  SET_PASSWORD: '/api-auth-djoser/users/set_password/',
  PLANIFICACION: `${WORKSPACE_PLANIFICACION}`,
  URBANISMO: `${WORKSPACE_URBANISMO}`,
  CATALOG: '/catalogo'
};

export const getEndpointWithBaseUrl = (url: string, type: TService) => {
  return `${BASE_URL}${url}/${type}`;
}

export const getEndpoint = (url: string, type: TService) => {
  return `${url}/${type}`;
}

/*
const ENDPOINTS = {
  PREDIOS: `${WORKSPACE_DEFAULT}/predios/wms`,
  MANZANAS: `${WORKSPACE_DEFAULT}/manzanos/wms`,
  VIAS: `${WORKSPACE_PLANIFICACION}/viasGral/wms`,
  ALL_PREDIOS: `${WORKSPACE_DEFAULT}/predios/wfs`,
  AREA_HOMOLOGADA: `${WORKSPACE_PLANIFICACION}/areaHomologadaUrbana/wms`,
  AREAS_VERDES: `${WORKSPACE_URBANISMO}/areasVerdes/wms`,
  EQUIPAMIENTOS_URBANOS: `${WORKSPACE_PLANIFICACION}/equipamientosUrbanos/wms`,
  LIMITE_MUNICIPAL: `${WORKSPACE_PLANIFICACION}/limiteMunicipal/wms`,
  LIMITES_DISTRITALES: `${WORKSPACE_PLANIFICACION}/limitesDistritales/wms`,
};
*/