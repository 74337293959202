import { createContext, useContext } from "react";
import { IBookmark } from "util/interfaces";
import { useBookmarks } from "hooks/useBookmarks";

interface IBookmarksContext {
  bookmarks: IBookmark[];
  addBookmark: (bookmark: IBookmark) => void;
}

const initialValue: IBookmarksContext = {
  bookmarks: [],
  addBookmark: () => {}
}

export const BookmarksContext = createContext<IBookmarksContext>(initialValue);

interface BookmarksProviderProps {
  mapId: number;
  children: React.ReactNode;
}

export const BookmarksProvider: React.FC<BookmarksProviderProps> = ({ mapId, children }) => {
  const { bookmarks, addBookmark } = useBookmarks(mapId);
  console.log('BOOKMARKS: ', { bookmarks });
  return (
    <BookmarksContext.Provider value={{ bookmarks, addBookmark }}>
      {children}
    </BookmarksContext.Provider>
  );
};

export const useCurrentBookmarks = () => useContext(BookmarksContext);
