import AccordionControl from "components/AccordionControl";
import L from "leaflet";
import { FC, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { useMap } from "react-leaflet";

const BookmarkControl: FC = () => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;
    const bookmark = new L.Control({ position: "topleft" });

    bookmark.onAdd = () => {
      const div = L.DomUtil.create("div", "");

      const accordionContainer = L.DomUtil.create("div", "", div);
      accordionContainer.id = "accordion-container";
      const root = createRoot(accordionContainer);
      root.render(<AccordionControl>Hi there!</AccordionControl>)

      return div;
    }

    bookmark.addTo(map);

    return () => {
      bookmark.remove();
    }
  }, [map]);

  return null;
}

export default BookmarkControl;
