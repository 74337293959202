import React, {
  FC,
  SetStateAction,
  Dispatch,
  useContext,
  useState,
  ReactNode,
  createContext,
} from "react";
import { IManzanoFeature, IPredioFeature } from "util/interfaces";

interface IStateContext {
  currentPredio: IPredioFeature | null;
  setCurrentPredio: Dispatch<SetStateAction<IPredioFeature | null>>;
}

interface IStateContextM {
  currentManzano: IManzanoFeature | null;
  setCurrentManzano: Dispatch<SetStateAction<IManzanoFeature | null>>;
}

const initialValue = {
  currentPredio: null,
  setCurrentPredio: () => {
    // eslint-disable-next-line no-console
    console.warn("Context is not yet Initialized");
  },
};

const initialValueM = {
  currentManzano: null,
  setCurrentManzano: () => {
    // eslint-disable-next-line no-console
    console.warn("Context no inicializado manzano");
  },
};

const StateContext = createContext<IStateContext>(initialValue);
const StateContextM = createContext<IStateContextM>(initialValueM);

interface ILanguageProviderProps {
  children: ReactNode;
}

export const StateProvider: FC<ILanguageProviderProps> = ({ children }) => {
  const [currentPredio, setCurrentPredio] = useState<IPredioFeature | null>(
    null
  );

  // console.log("--- currentLanguage ---", currentPredio);

  return (
    <StateContext.Provider
      value={{
        currentPredio,
        setCurrentPredio,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const StateProviderM: FC<ILanguageProviderProps> = ({ children }) => {
  const [currentManzano, setCurrentManzano] = useState<IManzanoFeature | null>(
    null
  );
  return (
    <StateContextM.Provider
      value={{
        currentManzano,
        setCurrentManzano,
      }}
    >
      {children}
    </StateContextM.Provider>
  );
};

export const useCurrentState = () => useContext(StateContext);

export const useCurrentStateM = () => useContext(StateContextM);
