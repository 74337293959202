import { getFeaturePredioInfo, IPredioInfoProps } from "api/features";
import { useFeature } from "hooks/useFeature";
import { FC, useState } from "react";
import { Polygon, Popup, useMap, useMapEvents, GeoJSON } from "react-leaflet";
import { Point } from "leaflet";
import FeatureInfo from "./FeatureInfo";
import { colors } from "theme/base";
import { useCurrentState, useCurrentStateM } from "context/StateContext";
import { mutationManzano, mutationPredio } from "util/Array";
import { useGlobalContext } from "state/Context";
import { Box, Dialog, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";
import SearchBar from "material-ui-search-bar";
import FeatureInfoM from "./FeatureInfoM";

const CustomMarker: FC = () => {
  const {
    state: {
      appState: {
        tools: { isIdentifier },
      },
    },
    dispatch,
  } = useGlobalContext();

  const mMap = useMap();
  const { currentPredio, setCurrentPredio } = useCurrentState();
  const { currentManzano, setCurrentManzano } = useCurrentStateM();
  const [position, setPosition] = useState({ lat: 0, lng: 0 });
  // const feature = useFeature();
  // const featureM = useFeature();

  const map = useMapEvents({
    click: async (event) => {
      if (isIdentifier) {
        console.log('será...');
        const { lat, lng } = event.latlng;
        setPosition({ lat: lat, lng: lng });
        const sizeMap: Point = mMap.getSize();
        const { x, y } = event.containerPoint;
        const data: IPredioInfoProps = {
          i: `${Math.floor(x)}`,
          j: `${Math.floor(y)}`,
          width: `${sizeMap.x}`,
          height: `${sizeMap.y}`,
          bbox: `${mMap.getBounds().getSouth()},${mMap
            .getBounds()
            .getWest()},${mMap.getBounds().getNorth()},${mMap
            .getBounds()
            .getEast()}`,
        };
        const result = await getFeaturePredioInfo(data);
        if (result) {
          console.log('cliqueando: ', result);
          if (Object.keys(result.features)?.length > 0) {
            setCurrentPredio(mutationPredio(result.features[0]));
            // setCurrentManzano(mutationManzano(result.features[0]));
          } else {
            setCurrentPredio(null);
            // setCurrentManzano(null);
          }
        }
      }
    },
    locationfound: (location) => {
      console.log("location found:", { location });
      /*const result = utm.fromLatLon(location.latlng.lat, location.latlng.lng);
      const result2 = utm.toLatLon(
        result.easting,
        result.northing,
        result.zoneNum,
        result.zoneLetter
      );*/
      //console.log("location result:", { result });
      //console.log("location result2:", { result2 });
      map.flyTo(location.latlng, map.getZoom());

      //let newBounds = mMap.getBounds();
      //console.log("BOUND CURRENT: ", { newBounds });
    },
  });
  //console.log('CURRENT PREDIO -------: ', currentLanguage?.properties.codsist);
  //console.log('CURRENT PREDIO -------: ', { currentLanguage });
  //console.log("ZOOM: ", map.getZoom());
  interface food {
    name: string;
    calories: number;
    fat: number;
    carbs: number;
    protein: number;
  }
  const geojsonFeature1 = JSON.parse(`{
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "id": "predios.19520",
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -66.249612,
                                -17.40386694
                            ],
                            [
                                -66.24942916,
                                -17.40389061
                            ],
                            [
                                -66.2494358,
                                -17.40426456
                            ],
                            [
                                -66.25133298,
                                -17.40414082
                            ],
                            [
                                -66.25135021,
                                -17.40361819
                            ],
                            [
                                -66.2503282,
                                -17.40377748
                            ],
                            [
                                -66.249612,
                                -17.40386694
                            ],
                            [
                                -66.249612,
                                -17.40386694
                            ]
                        ]
                    ]
                ]
            },
            "geometry_name": "geom",
            "properties": {
                "objectid": 19520,
                "dist": "08",
                "codigo": null,
                "codsist": "080025049",
                "shape_leng": 507.989734678,
                "shape_area": 9908.01124103
            }
        }
    ],
    "totalFeatures": "unknown",
    "numberReturned": 1,
    "timeStamp": "2023-09-22T19:00:00.642Z",
    "crs": {
        "type": "name",
        "properties": {
            "name": "urn:ogc:def:crs:EPSG::4326"
        }
    }
}`);
const geojsonFeature2 = JSON.parse(`{
  "type": "FeatureCollection",
  "features": [
      {
          "type": "Feature",
          "id": "predios.19510",
          "geometry": {
              "type": "MultiPolygon",
              "coordinates": [
                  [
                      [
                          [
                              -66.25138567,
                              -17.40470596
                          ],
                          [
                              -66.25139042,
                              -17.40419941
                          ],
                          [
                              -66.24953374,
                              -17.4043192
                          ],
                          [
                              -66.24952872,
                              -17.40465506
                          ],
                          [
                              -66.24955427,
                              -17.40483332
                          ],
                          [
                              -66.25138567,
                              -17.40470596
                          ]
                      ]
                  ]
              ]
          },
          "geometry_name": "geom",
          "properties": {
              "objectid": 19510,
              "dist": "08",
              "codigo": "17813",
              "codsist": "080025012",
              "shape_leng": 506.272551286,
              "shape_area": 11146.5151494
          }
      }
  ],
  "totalFeatures": "unknown",
  "numberReturned": 1,
  "timeStamp": "2023-09-22T19:21:50.395Z",
  "crs": {
      "type": "name",
      "properties": {
          "name": "urn:ogc:def:crs:EPSG::4326"
      }
  }
}`);
const geojsonFeature3 = JSON.parse(`{
  "type": "FeatureCollection",
  "features": [
      {
          "type": "Feature",
          "id": "predios.19511",
          "geometry": {
              "type": "MultiPolygon",
              "coordinates": [
                  [
                      [
                          [
                              -66.25139291,
                              -17.40403328
                          ],
                          [
                              -66.25189205,
                              -17.4039923
                          ],
                          [
                              -66.25169994,
                              -17.40356702
                          ],
                          [
                              -66.25140018,
                              -17.40360985
                          ],
                          [
                              -66.25139291,
                              -17.40403328
                          ],
                          [
                              -66.25139291,
                              -17.40403328
                          ]
                      ]
                  ]
              ]
          },
          "geometry_name": "geom",
          "properties": {
              "objectid": 19511,
              "dist": "08",
              "codigo": "8466",
              "codsist": "080025010",
              "shape_leng": 183.683821234,
              "shape_area": 2039.96966131
          }
      }
  ],
  "totalFeatures": "unknown",
  "numberReturned": 1,
  "timeStamp": "2023-09-25T19:30:03.107Z",
  "crs": {
      "type": "name",
      "properties": {
          "name": "urn:ogc:def:crs:EPSG::4326"
      }
  }
}`);

  // const originalRows: food[] = [
  //   { name: "Pizza", calories: 200, fat: 6.0, carbs: 24, protein: 4.0 },
  //   { name: "Hot Dog", calories: 300, fat: 6.0, carbs: 24, protein: 4.0 },
  //   { name: "Burger", calories: 400, fat: 6.0, carbs: 24, protein: 4.0 },
  //   { name: "Hamburger", calories: 500, fat: 6.0, carbs: 24, protein: 4.0 },
  //   { name: "Fries", calories: 600, fat: 6.0, carbs: 24, protein: 4.0 },
  //   { name: "Ice Cream", calories: 700, fat: 6.0, carbs: 24, protein: 4.0 }
  // ];
  // const [rows, setRows] = useState<food[]>(originalRows);
  return (
    <>
      {currentPredio && (
        // <><GeoJSON data={geojsonFeature1} /><GeoJSON data={geojsonFeature2} /><GeoJSON data={geojsonFeature3} /></>
        <Polygon
          pathOptions={{ color: colors.selectedFeature }}
          positions={currentPredio.positions!}
          opacity={1}
        >
          <Popup
            eventHandlers={{
              click: () => {
                console.log("marker clicked ZOOM: ", map.getZoom());
              },
            }}
            maxWidth={500}
          >
            {/* <Dialog open={true}>
              <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                <SearchBar
                  
                />
              </Box>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Food (100g serving)</TableCell>
                      <TableCell align="right">Calories</TableCell>
                      <TableCell align="right">Fat&nbsp;(g)</TableCell>
                      <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                      <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.calories}</TableCell>
                        <TableCell align="right">{row.fat}</TableCell>
                        <TableCell align="right">{row.carbs}</TableCell>
                        <TableCell align="right">{row.protein}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Dialog> */}
            <FeatureInfo feature={currentPredio.properties} />
            {/* <FeatureInfoM feature={currentManzano.properties} /> */}
          </Popup>
        </Polygon>
      )}
    </>
  );
};

export default CustomMarker;
