import { getUserInfo, postSignIn } from "api/Authentication";
import { userInfo } from "os";
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { loadState, saveState } from "util/LocalStorage";

export interface IUserInfo {
  email: string;
  id: number;
  username: string;
}

export interface IAuthenticationContext {
  loading: boolean;
  error: boolean;
  token: string | null;
  signed: boolean;
  userInfo: IUserInfo | null;
  callSignIn: (username: string, password: string) => Promise<boolean>;
  callUserInfo: () => Promise<boolean>;
}

const initialState: IAuthenticationContext = {
  loading: false,
  error: false,
  token: "",
  signed: false,
  userInfo: null,
  callSignIn: async (username: string, password: string): Promise<boolean> =>
    false,
  callUserInfo: async (): Promise<boolean> => false,
};

export const AuthenticationContext =
  createContext<IAuthenticationContext>(initialState);

interface IAuthenticationProviderProps {
  children: ReactNode;
}

export const AuthenticationProvider: FC<IAuthenticationProviderProps> = ({
  children,
}) => {
  const authInfo = loadState("AUTH_INFO");
  const [state, setState] = useState<IAuthenticationContext>(
    authInfo ? authInfo : initialState
  );

  const callSignIn = useCallback(
    async (username: string, password: string) => {
      if (!state.signed) {
        try {
          const { data } = await postSignIn({ username, password });
          if (data.auth_token) {
            console.log("IN TOKEN UPDATED", data.auth_token);
            setState((prevState) => ({
              ...prevState,
              loading: false,
              error: false,
              token: data.auth_token,
              signed: true,
            }));
            saveState("AUTH_INFO", state);
            return true;
          }
        } catch (e) {
          console.log("ERROR E: ", { e });
          setState((prevState) => ({
            ...prevState,
            loading: false,
            error: true,
            token: null,
            signed: false,
          }));
        }
      }
      return false;
    },
    [state]
  );

  const callUserInfo = useCallback(async () => {
    if (!state.token) return false;
    try {
      const data = await getUserInfo(state.token);
      const userData = data ?? null;
      setState((prevState) => ({
        ...prevState,
        userInfo: {
          ...prevState.userInfo,
          ...userData,
        },
      }));
      return userData === null;
    } catch (e) {
      return false;
    }
  }, [state.token]);

  return (
    <AuthenticationContext.Provider value={{ ...state, callSignIn, callUserInfo }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = () => useContext(AuthenticationContext);
