import { GridColDef } from "@mui/x-data-grid";

export const ColumnsHeader: GridColDef[] = [
  { field: "id", headerName: "ID", type: "number", width: 90 },
  {
    field: "nombreCapa",
    headerName: "Nombre Capa",
    width: 300,
    // editable: true,
  },
  {
    field: "wms",
    headerName: "WMS",
    width: 400,
    // editable: true,
  },
  {
    field: "wfs",
    headerName: "WFS",
    // type: "number",
    width: 400,
    // editable: true,
  },
];
